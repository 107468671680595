import {
  useState,
  useCallback,
  FC,
  ChangeEvent,
  FormEvent,
  useEffect,
} from 'react';
import intl from 'react-intl-universal';
import debounce from 'lodash/debounce';
import {
  Input,
  Datepicker,
  tailwindOverride,
  FormItem,
} from '@getsynapse/design-system';
import { NewActualHoursEntry } from 'types/store/actualHours';
import { rangeDate } from 'utils/customTypes';

interface AddHoursFormProps {
  userId: string;
  addNewActualHoursEntry: (
    newActualHoursEntry: NewActualHoursEntry | null
  ) => void;
}

const AddHoursForm: FC<AddHoursFormProps> = ({
  userId,
  addNewActualHoursEntry,
}) => {
  const [newActualHoursEntry, setNewActualHoursEntry] =
    useState<NewActualHoursEntry>({
      userId,
      hours: 0,
      date: new Date().toLocaleDateString(),
    });

  useEffect(() => {
    if (newActualHoursEntry.hours > 0) {
      addNewActualHoursEntry(newActualHoursEntry);
    } else {
      addNewActualHoursEntry(null);
    }
  }, [addNewActualHoursEntry, newActualHoursEntry]);

  const handleUpdateHours = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const duration = parseFloat(e.target.value) * 60;
    setNewActualHoursEntry((prevState) => ({
      ...prevState,
      hours: duration,
    }));
  }, []);

  const debouncedUpdateHours = debounce(handleUpdateHours, 300);

  return (
    <form
      aria-label='add-hours-form'
      onSubmit={(e: FormEvent<HTMLFormElement>) => e.preventDefault()}
      className={tailwindOverride('w-full flex')}
    >
      <FormItem className='w-4/6'>
        <Input
          className='rounded-none rounded-l'
          placeholder={intl.get('TASK_ACTUAL_HOURS.HOURS_FIELD_PLACEHOLDER')}
          onChange={debouncedUpdateHours}
          aria-label='hours-input'
          type='number'
        />
      </FormItem>
      <FormItem className='w-2/6'>
        <Datepicker
          inputProps={{
            className: 'rounded-none rounded-r border-l-0 hover:border-l',
            'aria-label': 'datepicker-input',
          }}
          className='w-full'
          useCustomModifier={false}
          startPlaceHolder={intl.get('TASK_ACTUAL_HOURS.TODAY')}
          onPickDate={(date: rangeDate) =>
            setNewActualHoursEntry((prevState) => ({
              ...prevState,
              date: (date.startDate as Date).toLocaleDateString(),
            }))
          }
        />
      </FormItem>
    </form>
  );
};

export default AddHoursForm;
