import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchTeamTasks,
  selectTeamTasksLoading,
  selectTeamTasks,
  selectCanFetchtMoreTasks,
  selectTotalCount,
  reorderTask,
  setFilters,
  selectTeamTasksTableFilters,
} from 'state/ProjectTasksList/teamTasks/teamTasksSlice';
import {
  setShouldRefreshTasks,
  selectShouldRefreshTasks,
  updateProjectTaskOrder,
  setFailedTaskReorder,
} from 'state/ProjectTasksList/actions/actionsSlice';
import { fetchFilterSettingByType } from 'state/Settings/Filters/FiltersSlice';
import { fetchProjectProgress } from 'state/Project/progress/progressSlice';
import { TaskListFilters } from 'types/store/tasksList';
import { TASKS_TABLE_TABS, SLICE_STATUS } from 'utils/constants';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';
import TasksTable from '../TasksTable/TasksTable';

const TeamTasks = () => {
  const dispatch = useDispatch();
  const teamTasksTableFilters = useSelector(selectTeamTasksTableFilters);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const fetchTeamTasksStatus = useSelector(selectTeamTasksLoading);
  const teamTasks = useSelector(selectTeamTasks);
  const canFetchMoreTasks = useSelector(selectCanFetchtMoreTasks);
  const totalCount = useSelector(selectTotalCount);
  const shouldRefreshTasks = useSelector(selectShouldRefreshTasks);
  const { projectId } = useParams<{ projectId: string }>();

  const init = useCallback(
    async (projectId: string) => {
      await dispatch(fetchFilterSettingByType(TASKS_TABLE_TABS.TEAM_TASKS));
      dispatch(fetchTeamTasks({ projectId, includeTotalCount: true }));
      dispatch(fetchProjectProgress({ projectId }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (projectId) {
      init(projectId);
    }
  }, [init, projectId]);

  useEffect(() => {
    if (shouldRefreshTasks) {
      dispatch(setShouldRefreshTasks(false));
      init(projectId);
    }
  }, [shouldRefreshTasks, init, dispatch, projectId]);

  useEffect(() => {
    if (fetchTeamTasksStatus) {
      if (fetchTeamTasksStatus === SLICE_STATUS.IDLE && isLoading) {
        setIsLoading(false);
      }
    }
  }, [fetchTeamTasksStatus, isLoading]);

  const handleFetchMoreTasks = useCallback(() => {
    dispatch(
      fetchTeamTasks({
        projectId,
        fetchNext: true,
      })
    );
  }, [dispatch, projectId]);

  const handleReorderTask = useCallback(
    async (taskId: string, startIndex: number, endIndex: number) => {
      const task = teamTasks[endIndex];
      const newOrder = parseInt(task.projectOrder, 10);
      if (isNaN(newOrder)) {
        dispatch(setFailedTaskReorder(true));
        return;
      }
      dispatch(
        reorderTask({
          taskId,
          startIndex,
          endIndex,
        })
      );
      try {
        dispatch(
          updateProjectTaskOrder({
            projectId,
            taskId,
            newOrder: parseInt(task.projectOrder),
          })
        );
      } catch (error) {
        dispatch(
          reorderTask({
            taskId,
            startIndex: endIndex,
            endIndex: startIndex,
          })
        );
      }
    },
    [dispatch, projectId, teamTasks]
  );

  const handleUpdateFilters = useCallback(
    (updatedFilters: TaskListFilters) => {
      dispatch(setFilters(updatedFilters));
      dispatch(
        fetchTeamTasks({
          projectId,
          fetchNext: false,
          includeTotalCount: true,
        })
      );
    },
    [dispatch, projectId]
  );

  if (isLoading) {
    return (
      <SkeletonLoader
        data-testid={`${TASKS_TABLE_TABS.TEAM_TASKS}__skeleton-loader`}
      />
    );
  }

  return (
    <TasksTable
      tableName={TASKS_TABLE_TABS.TEAM_TASKS}
      tasks={teamTasks}
      canFetchMoreTasks={canFetchMoreTasks}
      fetchMoreTasks={handleFetchMoreTasks}
      totalCount={totalCount}
      canReorderTasks
      reorderTask={handleReorderTask}
      filters={teamTasksTableFilters}
      onUpdateFilters={handleUpdateFilters}
    />
  );
};

export default TeamTasks;
