import {
  useState,
  useCallback,
  useMemo,
  ChangeEvent,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import { usePopper } from 'react-popper';
import Alert from '@reach/alert';
import classNames from 'classnames';
import intl from 'react-intl-universal';
import {
  List,
  useElevation,
  TextField,
  IconButton,
  Checkbox,
} from '@getsynapse/design-system';
import { COUNTRIES, LANGUAGES, SKILLS } from 'utils/constants';
import { ADD_FACILITATORS_FILTERS } from 'utils/constants/learningSchedule';
import { Option } from 'utils/customTypes';
import '../../../../../styles.css';

type FilterOptionPopupProps = {
  isOpen: boolean;
  selectedOption: string;
  itemRef: HTMLDivElement | null;
  selectedOptions: Option[];
  updateSelectedOptions: (options: Option[]) => void;
};

const FilterOptionPopup = forwardRef(
  (
    {
      isOpen,
      selectedOption,
      itemRef,
      selectedOptions,
      updateSelectedOptions,
    }: FilterOptionPopupProps,
    ref
  ) => {
    const popperElevation = useElevation(3);

    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
      null
    );
    const [filterValue, setFilterValue] = useState<string>('');

    const offsetYValue = useMemo(() => {
      if (selectedOption === ADD_FACILITATORS_FILTERS.SKILLS) {
        return 0;
      } else if (selectedOption === ADD_FACILITATORS_FILTERS.LANGUAGES) {
        return 40;
      } else {
        return 80;
      }
    }, [selectedOption]);

    const { styles, attributes, update } = usePopper(itemRef, popperElement, {
      placement: 'right-start',
      strategy: 'fixed',

      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [offsetYValue, 10],
          },
        },
      ],
    });

    useImperativeHandle(ref, () => ({
      update,
    }));

    const getOptions = (type: 'skills' | 'languages' | 'countries') => {
      let items,
        keyPrefix = '';

      if (type === 'skills') {
        items = SKILLS;
        keyPrefix = 'TEAMS.SKILLS.';
      } else if (type === 'languages') {
        items = LANGUAGES;
        keyPrefix = 'LANGUAGES.';
      } else {
        items = COUNTRIES;
        keyPrefix = 'COUNTRIES.';
      }

      return items.map((item) => ({
        value: item,
        label: intl.get(`${keyPrefix}${item}`),
      }));
    };

    const options = useMemo(() => {
      const allOptions = getOptions(
        selectedOption as 'skills' | 'languages' | 'countries'
      );

      if (!filterValue) return allOptions;

      const lowerCaseFilterValue = filterValue.toLowerCase();
      return allOptions.filter((option) =>
        option.label.toLowerCase().includes(lowerCaseFilterValue)
      );
    }, [filterValue, selectedOption]);

    useEffect(() => {
      if (!isOpen) {
        setFilterValue('');
      }
    }, [isOpen]);

    const isOptionSelected = (option: Option, selectedOptions: Option[]) => {
      return selectedOptions.some(
        (selected) =>
          selected.value === option.value && selected.label === option.label
      );
    };

    const handleOptionChange = useCallback(
      (option: Option, checked: boolean) => {
        const isSelected = isOptionSelected(option, selectedOptions);

        let updatedOptions;
        if (checked && !isSelected) {
          updatedOptions = [...selectedOptions, option];
        } else if (!checked && isSelected) {
          updatedOptions = selectedOptions.filter(
            (item) => item.value !== option.value
          );
        } else {
          updatedOptions = [...selectedOptions];
        }

        if (isOpen && update) {
          update();
        }
        updateSelectedOptions(updatedOptions);
      },
      [selectedOptions, updateSelectedOptions, isOpen, update]
    );

    const renderOption = useCallback(
      (option: Option) => {
        const isSelected = isOptionSelected(option, selectedOptions);

        const listItemClasses = classNames(
          'group text-base truncate px-4 py-2',
          {
            'hover:bg-secondary-lightest hover:shadow-list-item-hover-green':
              !isSelected,
            'hover:bg-primary': isSelected,
          }
        );

        const checkboxInputProps = {
          className: classNames({
            'group-hover:border-neutral-white': isSelected,
            'group-hover:border-secondary-dark': !isSelected,
          }),
        };

        const checkboxClasses = classNames('cursor-pointer', {
          'group-hover:text-secondary-darker': !isSelected,
          'group-hover:text-neutral-white': isSelected,
        });

        return (
          <li className={listItemClasses}>
            <Checkbox
              checked={isSelected}
              onChange={(event) =>
                handleOptionChange(option, event.target.checked)
              }
              label={option.label}
              inputProps={checkboxInputProps}
              className={checkboxClasses}
            />
          </li>
        );
      },
      [selectedOptions, handleOptionChange]
    );

    return (
      <div className='relative'>
        <Alert
          hidden={!isOpen}
          ref={setPopperElement}
          style={{ ...styles.popper, zIndex: 10 }}
          className={classNames(
            'w-80 rounded bg-neutral-white',
            popperElevation
          )}
          {...attributes.popper}
          data-testid='filter-option_popup'
        >
          <div>
            <TextField
              value={filterValue}
              variant='search'
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setFilterValue(event.target.value);
              }}
              placeholder={'Search'}
              tailingIcon={
                <IconButton
                  name='close-circle'
                  description='clear filter value'
                  onClick={() => setFilterValue('')}
                  className='text-primary-lighter'
                />
              }
              data-cy='autocomplete-search-input'
              className='my-2 mx-4'
            />
            <List
              selectedOptions={selectedOptions}
              options={options}
              className='shadow-0 pb-1 max-h-52 overflow-y-auto'
              renderOption={renderOption}
              onSelectOption={() => {}}
            />
          </div>
        </Alert>
      </div>
    );
  }
);

export default FilterOptionPopup;
