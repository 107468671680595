import { useMemo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  setTaskIdToEdit,
  selectTaskIdToEdit,
} from 'state/ProjectTasksList/actions/actionsSlice';

interface UseTaskRowActionsReturn {
  openTakInSidePanel: (taskId: string) => void;
  handleClickTaskRow: (taskId: string, projectId: string) => void;
}

interface LocationState {
  from: string;
}

const useTaskRowActions: () => UseTaskRowActionsReturn = () => {
  const dispatch = useDispatch();
  const location = useLocation<LocationState>();
  const history = useHistory();

  const taskIdToEdit = useSelector(selectTaskIdToEdit);

  const navigateToTaskDetailPage = useCallback(
    (taskId: string, projectId: string) => {
      const state = location.state;
      const fromPage = state?.from;
      const url = `${projectId}/tasks/${taskId}`;
      history.push(url, { from: fromPage });
    },
    [location, history]
  );

  const openTakInSidePanel = useCallback(
    (taskId: string) => {
      dispatch(setTaskIdToEdit(taskId));
    },
    [dispatch]
  );

  const handleClickTaskRow = useCallback(
    (taskId: string, projectId: string) => {
      const isSidePanelOpen = !!taskIdToEdit;
      if (isSidePanelOpen && taskIdToEdit !== taskId) {
        openTakInSidePanel(taskId);
      } else {
        navigateToTaskDetailPage(taskId, projectId);
      }
    },
    [navigateToTaskDetailPage, openTakInSidePanel, taskIdToEdit]
  );

  return useMemo(
    () => ({
      handleClickTaskRow,
      openTakInSidePanel,
    }),
    [handleClickTaskRow, openTakInSidePanel]
  );
};

export default useTaskRowActions;
