import {
  EventData,
  FetchedEvent,
  CreatedEvent,
  CreatedExceptionEvent,
} from 'utils/types/learningSchedule';

export const serializeEvents = (events: FetchedEvent[]): EventData[] => {
  const eventsMap = new Map<string, FetchedEvent>();
  events.forEach((event) => {
    eventsMap.set(event.id, event);
  });
  return events.map((event) => ({
    Id: event.id,
    Subject: event.subject,
    StartTime: event.startTime,
    EndTime: event.endTime,
    ProjectId: event.projectIds,
    IsAllDay: event.isAllDay,
    EventType: event.learningEventType,
    RecurrenceRule: event.parentId
      ? eventsMap.get(event.parentId)?.recurrenceRule
      : event.recurrenceRule,
    RecurrenceException: event.parentId
      ? eventsMap.get(event.parentId)?.recurrenceException
      : event.recurrenceException,
    projects: event.projects,
    facilitators: event.facilitators,
    RecurrenceID: event.parentId || null,
  }));
};

export const serializeCreatedEvent = (event: CreatedEvent): EventData => {
  return {
    Id: event.id,
    Subject: event.title,
    StartTime: event.startDateTime,
    EndTime: event.endDateTime,
    ProjectId: event.projects.map((project) => project.id),
    projects: event.projects.map((project) => ({
      id: project.id,
      title: project.title,
    })),
    facilitators: event.facilitators.map((facilitator) => ({
      id: facilitator.id,
      name: facilitator.name,
    })),
    IsAllDay: event.isAllDay,
    EventType: event.learningEventType,
    RecurrenceRule: event.recurrencePattern,
    RecurrenceException: event.recurrenceException,
    RecurrenceID: event.parentId || null,
  };
};

export const serializeExceptionEvent = (
  event: CreatedExceptionEvent
): EventData => {
  return {
    Id: event.id,
    Subject: event.title,
    StartTime: event.startDateTime,
    EndTime: event.endDateTime,
    ProjectId: event.projects.map((project) => project.id),
    projects: event.projects.map((project) => ({
      id: project.id,
      title: project.title,
    })),
    facilitators: event.facilitators.map((facilitator) => ({
      id: facilitator.id,
      name: facilitator.name,
    })),
    IsAllDay: event.isAllDay,
    EventType: event.learningEventType,
    RecurrenceRule: event.recurrencePattern,
    RecurrenceException: '',
    RecurrenceID: event.isException ? event.parent.id : null,
  };
};
