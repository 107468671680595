import { useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import {
  fetchActualHours,
  selectActualHoursAreLoading,
} from 'state/ActualHours/actualHoursSlice';
import { selectUserId } from 'state/User/userSlice';
import useModal from 'Hooks/useModal';
import useSnackbarNotification from 'Hooks/useSnackbarNotification';
import useUpdateActualHours from './hooks/useUpdateActualHours';
import SkeletonLoader from './TimeEntries/TimeEntriesSkeletonLoader';
import TimeEntriesList from './TimeEntries/TimeEntriesList';
import AddHoursForm from './AddHoursForm/AddHoursForm';

type TaskActualHoursModalProps = {
  taskId: string;
  canAddActualHours: boolean;
  shouldDisplayModal: boolean;
  toggleDisplayModal: () => void;
};

const TaskActualHoursModal = ({
  taskId,
  canAddActualHours = true,
  shouldDisplayModal = false,
  toggleDisplayModal,
}: TaskActualHoursModalProps) => {
  const dispatch = useDispatch();
  const isLoadingHours = useSelector(selectActualHoursAreLoading);
  const currentUserId = useSelector(selectUserId);
  const { Modal, modalProps, closeModal } = useModal();

  const handleCloseModal = useCallback(() => {
    toggleDisplayModal();
    closeModal();
  }, [closeModal, toggleDisplayModal]);

  const {
    SnackbarNotification,
    isNotificationVisible,
    showNotification,
    snackbarProps,
  } = useSnackbarNotification();

  const {
    canSave,
    addNewActualHoursEntry,
    handleSaveActualHours,
    handleDeletedHours,
  } = useUpdateActualHours({
    taskId,
    showNotification,
    onSuccessCallback: handleCloseModal,
  });

  useEffect(() => {
    if (shouldDisplayModal) {
      dispatch(fetchActualHours({ taskId }));
    }
  }, [dispatch, taskId, shouldDisplayModal]);

  const actionButtons = useMemo(
    () => [
      {
        children: intl.get('SAVE'),
        variant: 'primary',
        onClick: handleSaveActualHours,
        disabled: !canSave,
        'data-testid': 'save-actuals-button',
      },
      {
        children: intl.get('CANCEL'),
        variant: 'tertiary',
        onClick: handleCloseModal,
        'data-testid': 'cancel-actuals-button',
      },
    ],
    [handleCloseModal, canSave, handleSaveActualHours]
  );

  return (
    <>
      <Modal
        {...modalProps}
        title={intl.get('TASK_ACTUAL_HOURS.TITLE')}
        titleIcon={{
          name: 'time-outline',
          className: 'text-neutral font-medium',
        }}
        aria-label={intl.get('TASK_ACTUAL_HOURS.TITLE')}
        size='medium'
        actionButtons={actionButtons}
        closeModal={handleCloseModal}
        isOpen={shouldDisplayModal}
      >
        {canAddActualHours && (
          <AddHoursForm
            userId={currentUserId!}
            addNewActualHoursEntry={addNewActualHoursEntry}
          />
        )}
        {isLoadingHours && <SkeletonLoader />}
        {!isLoadingHours && (
          <TimeEntriesList
            isViewOnly={!canAddActualHours}
            onDeleteHours={handleDeletedHours}
          />
        )}
      </Modal>
      {isNotificationVisible && snackbarProps && (
        <SnackbarNotification {...snackbarProps} />
      )}
    </>
  );
};

export default TaskActualHoursModal;
