import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'state/store';
import { NewTask } from 'types/store/newTask';
import { createNewTask } from 'api/newTask';
import { deleteTask } from 'api/taskDetail';
import { reorderTasks } from 'api/tasksList';

interface ProjectTasksListActionsState {
  taskIdToEdit?: string;
  taskIdToDuplicate?: string;
  shouldRefreshTasks?: boolean;
  taskDeleted?: string;
  failedTaskReorder?: boolean;
}

interface CreateNewTaskParams {
  newTask: NewTask;
  userId: string;
}

const initialState: ProjectTasksListActionsState = {};

export const createProjectTask = createAsyncThunk(
  'projectTasksList/createProjectTask',
  async ({ newTask }: CreateNewTaskParams, { rejectWithValue, dispatch }) => {
    try {
      const response = await createNewTask(newTask);
      dispatch(setShouldRefreshTasks(true));
      return response.successes[0];
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue(error);
    }
  }
);

export const deleteProjectTask = createAsyncThunk(
  'projectTasksList/deleteProjectTask',
  async (taskId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteTask(taskId);
      dispatch(setTaskDeleted(taskId));
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue(error);
    }
  }
);

interface UpdateProjectTaskOrderParams {
  projectId: string;
  taskId: string;
  newOrder: number;
}

export const updateProjectTaskOrder = createAsyncThunk(
  'projectTasksList/updateProjectTaskOrder',
  async (params: UpdateProjectTaskOrderParams, { rejectWithValue }) => {
    try {
      await reorderTasks(params);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error reordering tasks');
    }
  }
);

const actionsSlice = createSlice({
  name: 'projectTasksList/actions',
  initialState,
  reducers: {
    setTaskIdToEdit(state, action) {
      state.taskIdToEdit = action.payload;
    },
    setShouldRefreshTasks(state, action) {
      state.shouldRefreshTasks = action.payload;
    },
    setTaskDeleted(state, action) {
      state.taskDeleted = action.payload;
    },
    setFailedTaskReorder(state, action) {
      state.failedTaskReorder = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProjectTask.rejected, (state, action) => {
        throw new Error(action.payload as string);
      })
      .addCase(deleteProjectTask.rejected, (state, action) => {
        throw new Error(action.payload as string);
      })
      .addCase(updateProjectTaskOrder.rejected, (state, action) => {
        state.failedTaskReorder = true;
        throw new Error(action.payload as string);
      });
  },
});

const selectAllProjectTasksListActions = (state: RootState) =>
  state.projectTasksList.actions;

export const selectTaskIdToEdit = createSelector(
  [selectAllProjectTasksListActions],
  (actions: ProjectTasksListActionsState) => actions.taskIdToEdit
);

export const selectShouldRefreshTasks = createSelector(
  [selectAllProjectTasksListActions],
  (actions: ProjectTasksListActionsState) => actions.shouldRefreshTasks ?? false
);

export const selectTaskDeleted = createSelector(
  [selectAllProjectTasksListActions],
  (actions: ProjectTasksListActionsState) => actions.taskDeleted
);

export const selectFailedTaskReorder = createSelector(
  [selectAllProjectTasksListActions],
  (actions: ProjectTasksListActionsState) => actions.failedTaskReorder ?? false
);

export const {
  setTaskIdToEdit,
  setShouldRefreshTasks,
  setTaskDeleted,
  setFailedTaskReorder,
} = actionsSlice.actions;

export default actionsSlice.reducer;
