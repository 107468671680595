import { getDifference } from 'Pages/ProjectPage/helpers/updatedProjectData';
import { TaskDetailToUpdate, TaskState } from 'types/store/taskDetail';
import { TASK_STATUS } from 'utils/constants';

export const compareDates = (originalDate?: string, newDate?: string) => {
  if (newDate && !originalDate) {
    return true;
  }

  if (newDate && originalDate) {
    return new Date(newDate).getTime() !== new Date(originalDate).getTime();
  }

  if (originalDate && !newDate) {
    return true;
  }

  return false;
};

export const formatDate = (date: Date | string) => {
  return new Date(date).toLocaleDateString();
};

export const getTakDetailsToUpdate: (
  originalTaskDetail: TaskState,
  newTaskDetail: TaskDetailToUpdate
) => TaskDetailToUpdate = (originalTaskDetail, newTaskDetail) => {
  const {
    startDate: originalStartDate,
    endDate: originalEndDate,
    completedDate: originalCompletionDate,
    ...restOfOriginalTaskDetail
  } = originalTaskDetail;
  const {
    startDate: newStartDate,
    dueDate: newEndDate,
    completionDate: newCompletionDate,
    assignees,
    ...restOfTaskDetail
  } = newTaskDetail;
  const difference = getDifference(restOfTaskDetail, restOfOriginalTaskDetail);

  if (compareDates(originalStartDate, newStartDate)) {
    difference.startDate = newStartDate;
  }

  if (compareDates(originalEndDate, newEndDate)) {
    difference.dueDate = newEndDate;
  }

  if (compareDates(originalCompletionDate, newCompletionDate)) {
    difference.completionDate = newCompletionDate;
  }

  if (assignees) {
    if (assignees.toAdd.length > 0) {
      if (!difference.assignees) {
        difference.assignees = {};
      }
      difference.assignees.toAdd = assignees.toAdd;
    }

    if (assignees.toRemove.length > 0) {
      if (!difference.assignees) {
        difference.assignees = {};
      }
      difference.assignees.toRemove = assignees.toRemove;
    }
  }

  difference.id = originalTaskDetail.id!;

  return difference as TaskDetailToUpdate;
};

export const validateRequiredTaskFields: (
  taskDetail: TaskDetailToUpdate,
  hasAssignedUsers: boolean
) => Map<string, boolean> = (taskDetail, hasAssignedUsers) => {
  const errorsMap = new Map<string, boolean>();
  if (!taskDetail.name || taskDetail.name === '') {
    errorsMap.set('name', true);
  }

  if (hasAssignedUsers) {
    if (!taskDetail.startDate || !taskDetail.dueDate) {
      errorsMap.set('startDate', true);
    }
  }

  if (
    taskDetail.status === TASK_STATUS.COMPLETED &&
    !taskDetail.completionDate
  ) {
    errorsMap.set('completionDate', true);
  }

  return errorsMap;
};

export const validateIfTaskDueDateIsPast = (dueDate?: string) => {
  if (dueDate) {
    return new Date(dueDate).getTime() < new Date().getTime();
  }
  return false;
};
