import { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TaskListFilters } from 'types/store/tasksList';
import { ProjectTasksTableTab } from 'utils/customTypes';
import { RootState } from 'state/store';
import {
  createFilterSetting,
  selectFiltersSettingsTypeId,
  updateFilterSetting,
} from 'state/Settings/Filters/FiltersSlice';
import SearchFilter from 'Pages/TasksListPage/components/v2/TasksListTable/ActionsHeader/SearchFilter';
import TasksFiltersSidePanel from 'Pages/TasksListPage/components/v2/TasksFiltersSidePanel/TasksFiltersSidePanel';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';
import AppliedFiltersTags from 'Pages/TasksListPage/components/v2/AppliedFiltersTags/AppliedFiltersTags';

interface ActionsTableHeaderProps {
  tableName: ProjectTasksTableTab;
  filters: TaskListFilters;
  onUpdateFilters: (filters: TaskListFilters) => void;
  canFilter?: boolean;
}

const ActionsTableHeader: FC<ActionsTableHeaderProps> = ({
  filters,
  tableName,
  onUpdateFilters,
  canFilter = true,
}) => {
  const dispatch = useDispatch();
  const [shouldDisplayFilterComponent, setShouldDisplayFilterComponent] =
    useState<boolean>(false);

  const toggleFilterComponent = () =>
    setShouldDisplayFilterComponent((prev) => !prev);

  const filtersSettingsTypeId = useSelector((state: RootState) =>
    selectFiltersSettingsTypeId(state, tableName)
  );

  const handleSetFiltersSettings = useCallback(
    (filtersSettings: TaskListFilters) => {
      if (filtersSettingsTypeId) {
        dispatch(
          updateFilterSetting({
            id: filtersSettingsTypeId,
            updateFields: { filter_settings: filtersSettings },
          })
        );
      } else {
        dispatch(
          createFilterSetting({
            filter_type: tableName,
            filter_settings: filtersSettings,
          })
        );
      }
      onUpdateFilters({
        ...filtersSettings,
        search: filters.search,
      });
    },
    [
      filtersSettingsTypeId,
      onUpdateFilters,
      tableName,
      dispatch,
      filters.search,
    ]
  );

  const handleSearchValueChange = useCallback(
    (searchValue: string) => {
      onUpdateFilters({ ...filters, search: searchValue });
    },
    [filters, onUpdateFilters]
  );

  return (
    <>
      <TasksFiltersSidePanel
        open={shouldDisplayFilterComponent}
        onClose={toggleFilterComponent}
        filters={filters}
        onUpdateFilters={handleSetFiltersSettings}
        isCentralizedTasksPage={false}
      />
      <SearchFilter
        initialSearchFilterValue={filters.search || ''}
        onSearchFilterChange={handleSearchValueChange}
        tableName={tableName}
      >
        <FilterButton
          aria-label={`${tableName}__filter-button`}
          onToggleFilter={toggleFilterComponent}
          aria-pressed={shouldDisplayFilterComponent}
          disabled={!canFilter}
        />
      </SearchFilter>
      <AppliedFiltersTags
        filters={filters}
        onUpdateFilters={handleSetFiltersSettings}
        tableName={tableName}
      />
    </>
  );
};

export default ActionsTableHeader;
