import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { fetchProjectsList, Project } from 'api/projectsList';
import { fetchFacilitatorsList } from 'api/facilitatorsList';
import {
  EventData,
  ScheduleProjectResource,
  NewScheduleCreationParams,
  ScheduleProjectOption,
  Facilitator,
  ScheduleUpdateParams,
  ProjectResource,
  ExceptionCreationParams,
  SearchFilterParams,
} from 'utils/types/learningSchedule';
import {
  serializeEvents,
  serializeCreatedEvent,
  serializeExceptionEvent,
} from './helpers';
import { RootState } from 'state/store';
import ScheduleAPI from './ScheduleAPI';
import { EventFilters } from 'utils/types/filters';

interface ScheduleState {
  status: Status;
  value: EventsValue;
  projects: ProjectResource[];
  facilitators: Facilitator[];
  projectsForDropdown: Project[];
  search: string;
  filters: EventFilters;
}

type EventsValue = {
  events: EventData[];
  mySchedule: EventData[];
};

/* ============================= INITIAL STATE ============================== */

const initialState: ScheduleState = {
  value: {
    events: [],
    mySchedule: [],
  },
  status: SLICE_STATUS.IDLE,
  projects: [],
  facilitators: [],
  projectsForDropdown: [],
  search: '',
  filters: {},
};

/* ============================= ACTIONS ============================== */
export const updateSearchTerm = createAction<{
  search: string;
}>('SCHEDULE/UPDATE_SEARCH_TERM');

export const setFilters = createAction<EventFilters>('SCHEDULE/SET_FILTERS');

/* ============================== REDUX THUNK =============================== */
export const createEvent = createAsyncThunk(
  'SCHEDULE/CREATE_EVENT',
  async (params: NewScheduleCreationParams) => {
    const response = await ScheduleAPI.createSchedule(params);
    return response.data;
  }
);

export const fetchProjects = createAsyncThunk(
  'SCHEDULE/FETCH_PROJECTS',
  async () => {
    const response = await fetchProjectsList();
    return response;
  }
);

export const editEvent = createAsyncThunk(
  'SCHEDULE/EDIT_EVENT',
  async (params: ScheduleUpdateParams) => {
    const scheduleId = params.scheduleId || '';
    const updateParams = {
      learningEvent: params.learningEvent,
      schedule: params.schedule,
      resetExceptions: params.resetExceptions,
    };
    const response = await ScheduleAPI.updateSchedule(scheduleId, updateParams);
    return {
      data: response.data,
      resetExceptions: params.resetExceptions,
    };
  }
);

export const createException = createAsyncThunk(
  'SCHEDULE/CREATE_EXCEPTION',
  async (params: ExceptionCreationParams) => {
    const response = await ScheduleAPI.createException(params);
    return response.data;
  }
);

export const fetchEvents = createAsyncThunk(
  'SCHEDULE/FETCH_EVENTS',
  async (params: SearchFilterParams) => {
    const response = await ScheduleAPI.fetchEvents(params); // fetchEvents
    return response;
  }
);

export const fetchMySchedule = createAsyncThunk(
  'SCHEDULE/FETCH_MY_SCHEDULE',
  async () => {
    const response = await ScheduleAPI.fetchMySchedule();
    return response.serializedResponse;
  }
);

export const deleteEvent = createAsyncThunk(
  'SCHEDULE/DELETE_EVENT',
  async (scheduleId: string) => {
    const response = await ScheduleAPI.deleteSchedule(scheduleId);
    return response.data;
  }
);

export const fetchFacilitators = createAsyncThunk(
  'SCHEDULE/FETCH_FACILITATORS',
  async (params?: {
    search?: string;
    filters?: {
      languages: string[];
      skills: string[];
      countries: string[];
    };
  }) => {
    const response = await fetchFacilitatorsList(
      params?.search,
      params?.filters
    );
    return response;
  }
);

/* ================================= REDUCER ================================ */
const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createEvent.fulfilled, (state, action) => {
        if (action.payload) {
          const serializedEvent = serializeCreatedEvent(action.payload);
          state.value.events.push(serializedEvent);
          state.status = SLICE_STATUS.IDLE;
        }
      })
      .addCase(createEvent.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(createEvent.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.projectsForDropdown = action.payload.projects;
      })
      .addCase(fetchFacilitators.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchFacilitators.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchFacilitators.fulfilled, (state, action) => {
        state.facilitators = action.payload;
      })
      .addCase(editEvent.fulfilled, (state, action) => {
        if (action.payload) {
          const serializedEvent = serializeCreatedEvent(action.payload.data);
          const index = state.value.events.findIndex(
            (event) => event.Id === serializedEvent.Id
          );
          if (serializedEvent.RecurrenceID) {
            const parentEvent = state.value.events.find(
              (event) => event.Id === serializedEvent.RecurrenceID
            );
            if (parentEvent) {
              serializedEvent.RecurrenceRule = parentEvent.RecurrenceRule;
            }
          }
          state.value.events[index] = serializedEvent;
          if (action.payload.resetExceptions) {
            state.value.events = state.value.events.filter(
              (event) => event.RecurrenceID !== serializedEvent.Id
            );
          }
          state.status = SLICE_STATUS.IDLE;
        }
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.value.events = serializeEvents(action.payload.formattedSchedules);
        state.projects = action.payload.projectsMatchingSearchParams;
      })
      .addCase(fetchMySchedule.fulfilled, (state, action) => {
        state.value.mySchedule = serializeEvents(
          action.payload.formattedSchedules
        );
      })
      .addCase(updateSearchTerm, (state, action) => {
        state.search = action.payload.search;
      })
      .addCase(editEvent.rejected, () => {
        throw new Error();
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.value.events = state.value.events.filter((event) => {
          return (
            event.Id !== action.payload.deletedEventId &&
            event.RecurrenceID !== action.payload.deletedEventId
          );
        });
      })
      .addCase(deleteEvent.rejected, () => {
        throw new Error();
      })
      .addCase(setFilters, (state, action) => {
        state.filters = action.payload || {};
      })
      .addCase(createException.fulfilled, (state, action) => {
        if (action.payload) {
          const serializedException = serializeExceptionEvent(action.payload);
          const parentId = action.payload.parent.id;
          const parentException = action.payload.parent.recurrenceException;
          const parentIndex = state.value.events.findIndex(
            (event) => event.Id === parentId
          );
          const parentEvent = state.value.events[parentIndex];
          if (parentEvent) {
            serializedException.RecurrenceRule = parentEvent.RecurrenceRule;
          }
          state.value.events[parentIndex].RecurrenceException = parentException;
          state.value.events.push(serializedException);
        }
      });
  },
});

/* =============================== SELECTORS ================================ */

export const selectScheduleEvents = (state: RootState) =>
  state.schedule.value.events;

export const selectMyScheduleEvents = (state: RootState) =>
  state.schedule.value.mySchedule;

export const selectSearchTerm = (state: RootState) => state.schedule.search;

export const selectScheduleResources = (
  state: RootState
): ScheduleProjectResource[] =>
  state.schedule.projects.map((project) => ({
    ProjectId: project.id,
    Title: project.title,
  }));

export const selectProjectsForDropdown = (
  state: RootState
): ScheduleProjectOption[] =>
  state.schedule.projectsForDropdown.map((project) => ({
    id: project.id,
    title: project.title,
    status: project.status,
  }));

export const selectFacilitatorsForDropdown = (
  state: RootState
): Facilitator[] => state.schedule.facilitators;

export default scheduleSlice.reducer;
