import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  selectCollaboratedProjects,
  selectParticipatedProjects,
  selectAssociatedTeamsCount,
  selectUserRole,
  selectUserType,
  isUserOrganizationAdmin,
  isUserFacilitator,
} from 'state/User/userSlice';
import { PATHS, USER_ROLES, USER_TYPES } from 'utils/constants';

const usePathPermission = () => {
  const inInsightsModule = !!useRouteMatch({
    path: PATHS.INSIGHTS,
  });
  const inRequestModule = !!useRouteMatch({
    path: [
      `${PATHS.REQUEST_PAGE}/:requestId`,
      PATHS.REQUEST_PAGE,
      PATHS.REQUESTS_LIST_PAGE,
    ],
  });
  const inProjectsModule = !!useRouteMatch({
    path: [
      `${PATHS.NEW_PROJECT_PAGE}/:templateId`,
      `${PATHS.PROJECT_PAGE}/:projectId`,
      `${PATHS.PROJECT_PAGE}/:projectId/tasks/:taskId`,
      PATHS.PROJECTS_LIST_PAGE,
    ],
  });
  const inTeamsModule = !!useRouteMatch({
    path: PATHS.TEAMS,
  });
  const inSettingsModule = !!useRouteMatch({
    path: [
      PATHS.SETTINGS,
      `${PATHS.EDIT_TEAM}/:teamType/:teamId`,
      `${PATHS.USER_PAGE}/:userId/:userType?`,
      `${PATHS.SETTINGS}${PATHS.FORM_PAGE}`,
      `${PATHS.VENDOR_PAGE}/:vendorId`,
      `${PATHS.SETTINGS}${PATHS.FORM_PAGE}/:formId`,
    ],
  });
  const isLearningScheduleModule = !!useRouteMatch({
    path: [PATHS.LEARNING_SCHEDULE_PAGE],
  });
  const { tasksAssignmentsBasedResourcesCapacity = false } = useFlags();
  const history = useHistory();
  const userType = useSelector(selectUserType);
  const userRole = useSelector(selectUserRole);
  const isFacilitator = useSelector(isUserFacilitator);
  const isUserAdmin = useSelector(isUserOrganizationAdmin);
  const collaboratedProjects = useSelector(selectCollaboratedProjects);
  const participatedProjects = useSelector(selectParticipatedProjects);
  const associatedTeamsCount = useSelector(selectAssociatedTeamsCount);
  const projectsCurrentUserIsTakingPartIn =
    tasksAssignmentsBasedResourcesCapacity
      ? participatedProjects
      : collaboratedProjects;

  useEffect(() => {
    if (inInsightsModule && userRole && userRole !== USER_ROLES.ADMIN) {
      history.push(PATHS.ROOT);
    }
  }, [history, inInsightsModule, userType, userRole]);

  useEffect(() => {
    if (
      inRequestModule &&
      userType &&
      !(userType === USER_TYPES.L_D || userType === USER_TYPES.BUSINESS)
    ) {
      history.push(PATHS.ROOT);
    }
  }, [history, inRequestModule, userType]);

  useEffect(() => {
    if (
      inProjectsModule &&
      userType &&
      projectsCurrentUserIsTakingPartIn &&
      !(
        userType === USER_TYPES.L_D ||
        projectsCurrentUserIsTakingPartIn.length > 0
      )
    ) {
      history.push(PATHS.ROOT);
    }
  }, [projectsCurrentUserIsTakingPartIn, history, inProjectsModule, userType]);

  useEffect(() => {
    if (
      inTeamsModule &&
      userType &&
      associatedTeamsCount &&
      !(
        userType === USER_TYPES.L_D &&
        (userRole === USER_ROLES.ADMIN || associatedTeamsCount > 0)
      )
    ) {
      history.push(PATHS.ROOT);
    }
  }, [history, inTeamsModule, associatedTeamsCount, userType, userRole]);

  useEffect(() => {
    if (
      inSettingsModule &&
      userType &&
      userRole &&
      !(userType === USER_TYPES.L_D && userRole === USER_ROLES.ADMIN)
    ) {
      history.push(PATHS.ROOT);
    }
  }, [history, inSettingsModule, userRole, userType]);

  useEffect(() => {
    if (
      isLearningScheduleModule &&
      userType &&
      !isUserAdmin &&
      !isFacilitator
    ) {
      history.push(PATHS.ROOT);
    }
  }, [history, isLearningScheduleModule, isUserAdmin, isFacilitator, userType]);
};

export default usePathPermission;
