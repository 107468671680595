import { FC } from 'react';
import { Droppable, DroppableProvided } from 'react-beautiful-dnd';
import intl from 'react-intl-universal';
import { TaskWithAssignees } from 'types/store/projectTasksList';
import { ProjectTasksTableTab } from 'utils/customTypes';
import RenderNoRecords from 'Pages/RequestsListPage/components/NoRecords';
import TaskRow from './TaskRow';
import LastTaskRow from './LastTaskRow';
import CreateTaskModal from 'Pages/TasksListPage/components/v2/CreateTaskModal/CreateTaskModal';
import useTaskRowActions from './useTaskRowActions';
import EmptyTasks from 'assets/images/empty-tasks.svg';

interface TableBodyProps {
  tableName: ProjectTasksTableTab;
  tasks: TaskWithAssignees[];
  canFetchMoreTasks: boolean;
  fetchMoreTasks: () => void;
  showDisabledColumn?: boolean;
  canReorderTasks?: boolean;
  isDragging?: boolean;
}

const TableBody: FC<TableBodyProps> = ({
  tasks,
  tableName,
  canFetchMoreTasks,
  fetchMoreTasks,
  showDisabledColumn = false,
  canReorderTasks = false,
  isDragging = false,
}) => {
  const isListEmpty = tasks.length === 0;
  const { openTakInSidePanel, handleClickTaskRow } = useTaskRowActions();

  return (
    <Droppable droppableId={tableName}>
      {(droppableProvided: DroppableProvided) => (
        <tbody
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}
        >
          {tasks.map((task: TaskWithAssignees, index: number) => {
            const isOdd = index % 2 !== 0;
            return (
              <TaskRow
                key={task.id}
                index={index}
                task={task}
                bgColor={isOdd ? 'bg-neutral-lightest-two' : 'bg-neutral-white'}
                showDisabledColumn={showDisabledColumn}
                openTakInSidePanel={openTakInSidePanel}
                handleClickTaskRow={handleClickTaskRow}
                canReorderTasks={canReorderTasks}
              />
            );
          })}
          {!isListEmpty && !isDragging && canFetchMoreTasks && (
            <LastTaskRow
              isOdd={tasks.length % 2 !== 0}
              tableName={tableName}
              onLastRowInView={fetchMoreTasks}
              showDisabledColumn={showDisabledColumn}
            />
          )}
          {isListEmpty && (
            <tr aria-label={`${tableName}-empty-body`}>
              <td colSpan={10}>
                <RenderNoRecords
                  caption={intl.get('TASKS.TABLE.EMPTY')}
                  imageSrc={EmptyTasks}
                  className='h-empty-table-body w-full'
                  labelClassName='mt-0'
                  imageClassName='-ml-4'
                >
                  <div className='flex-initial self-center mt-2'>
                    <CreateTaskModal isCentralizedPage={false} />
                  </div>
                </RenderNoRecords>
              </td>
            </tr>
          )}
          {droppableProvided.placeholder}
        </tbody>
      )}
    </Droppable>
  );
};

export default TableBody;
