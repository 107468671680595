import intl from 'react-intl-universal';
import React, { useMemo, useState } from 'react';
import {
  FormItem,
  Toggle,
  Typography,
  TextField,
  Dropdown,
  Button,
  FileUpload,
} from '@getsynapse/design-system';
import { COUNTRIES, USER_TYPES, USER_ROLES } from 'utils/constants';
import {
  Option,
  AllUsersType,
  objKeyAsString,
  AvatarUser,
  BusinessUser,
  LDUser,
} from 'utils/customTypes';
import get from 'lodash/get';
import { getImageFileStackConfig } from 'utils/filestack';
import { State } from 'country-state-city';
import { PickerFileMetadata } from 'filestack-js';
import UserAvatar from 'Atoms/UserAvatar';
import ChangeUserTypeModal from './ChangeUserTypeModal';

const BasicInformation = ({
  user,
  userType,
  handleChangeField,
  country,
  setCountry,
  errors,
  isProfileEdit = false,
  isFormDirty = false,
}: {
  user?: Partial<AllUsersType>;
  userType: string;
  handleChangeField: (
    eventTargetValue: string | boolean,
    pathToUpdate: string
  ) => void;
  country: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  errors: objKeyAsString;
  isProfileEdit?: boolean;
  isFormDirty?: boolean;
}) => {
  const [displayEditPicture, setEditPictureButton] = useState(true);
  const countryOptions = useMemo(
    () =>
      COUNTRIES.map((country) => ({
        value: country,
        label: intl.get(`COUNTRIES.${country}`),
      })),
    []
  );
  const firstName = get(user, 'data.firstName');
  const lastName = get(user, 'data.lastName');
  const email = get(user, 'data.email');
  const userRole = get(user, 'role');
  const [currentUserData, setCurrentUserData] = useState<AvatarUser>({
    avatar_url: get(user, 'avatar_url'),
    data: {
      firstName,
      lastName,
    },
  });
  const [province, setProvince] = useState(get(user, 'data.province'));
  const provinceOptions = useMemo(() => {
    const provinces = State.getStatesOfCountry(country);
    return provinces.map((province) => ({
      value: province.name,
      label: province.name,
    }));
  }, [country]);
  const avatarUploadConfig = getImageFileStackConfig();
  const [showChangeUserTypeModal, setShowChangeUserTypeModal] = useState(false);

  return (
    <div className='grid grid-cols-2 gap-x-20 gap-y-4'>
      <div className='col-span-2'>
        <Typography variant='h5' className='mt-4'>
          {intl.get('SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.TITLE')}
        </Typography>
        <Typography variant='caption' className='block text-neutral-light'>
          {intl.get('SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.CAPTION')}
        </Typography>
      </div>
      <div className='flex'>
        <UserAvatar
          user={currentUserData}
          size='large'
          className='w-24 h-24 bg-center'
        />
        <div className='flex-grow'>
          <Typography
            variant='body2'
            className='font-semibold text-tiny mt-4 ml-3 text-neutral-black'
          >
            {intl.get(
              'SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.PROFILE_PICTURE'
            )}
          </Typography>
          {displayEditPicture ? (
            <Button
              variant='secondary'
              size='default'
              onClick={() => {
                setEditPictureButton(false);
              }}
              className='mt-2 ml-3'
              data-cy='edit-picture_button'
            >
              {intl.get(
                'SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.EDIT_PICTURE'
              )}
            </Button>
          ) : (
            <>
              <div className='flex py-2 pl-3'>
                <FileUpload
                  className='m-0 flex-grow'
                  config={avatarUploadConfig}
                  displayUploadedFiles={false}
                  onFileUploadHandle={(files: Array<PickerFileMetadata>) => {
                    const url = get(files, '0.url');
                    if (url) {
                      handleChangeField(url, 'avatar_url');
                      setCurrentUserData((userData) => {
                        setEditPictureButton(true);
                        return {
                          ...userData,
                          avatar_url: url,
                        };
                      });
                    }
                  }}
                  hidePickerWhenUploading
                  files={currentUserData.avatar_url}
                  data-cy='basicInformation-file-picker'
                />
                <Button
                  variant='tertiary'
                  size='small'
                  className='ml-2'
                  onClick={() => {
                    setEditPictureButton(true);
                  }}
                  data-cy='cancel-file-attach-button'
                >
                  {intl.get('PROJECT_DETAIL.FILES_TAB.CANCEL')}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      <div className='flex mt-4'>
        {user?.id && !isProfileEdit && (
          <div className='inline-block mr-14' data-cy='section_user-type'>
            <Typography
              variant='body2'
              className='m-0 leading-5 font-semibold text-tiny text-neutral-black'
            >
              {intl.get('SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.USER_TYPE')}
            </Typography>
            <div className='flex flex-row gap-6 items-end'>
              <Typography
                variant='body2'
                className='m-0 leading-6 pt-4 font-normal text-tiny text-neutral-black'
              >
                {intl.get(
                  `USERS_PAGE.TABLE.USER_TYPE.${userType.toLocaleUpperCase()}`
                )}
              </Typography>
              {userType === USER_TYPES.BUSINESS && (
                <div className='inline-block' data-cy='section_user-type'>
                  <Button
                    variant='secondary'
                    onClick={() => setShowChangeUserTypeModal(true)}
                    disabled={isFormDirty}
                  >
                    {intl.get(
                      'SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.CHANGE_TO_LEARNING_USER'
                    )}
                  </Button>
                  <ChangeUserTypeModal
                    isOpen={showChangeUserTypeModal}
                    closeModal={() => setShowChangeUserTypeModal(false)}
                    user={user as BusinessUser}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {userType === USER_TYPES.L_D && !isProfileEdit && (
          <>
            <Toggle
              label={intl.get(
                'SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.ADMIN_TOGGLE_LABEL'
              )}
              offText={intl.get(
                'SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.TOGGLE_OFF_TEXT'
              )}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeField(
                  e.target.checked ? USER_ROLES.ADMIN : USER_ROLES.USER,
                  'role'
                );
              }}
              checked={userRole === USER_ROLES.ADMIN}
              onText={intl.get(
                'SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.TOGGLE_ON_TEXT'
              )}
              className='text-neutral-black text-sm font-semibold mr-20'
              labelProps={{
                className: 'mb-5',
                'data-testid': 'make-admin_toggle',
              }}
              isSmall
              id='make-admin-toggle'
            />
            <Toggle
              label={intl.get(
                'SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.FACILITATOR_TOGGLE_LABEL'
              )}
              offText={intl.get(
                'SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.TOGGLE_OFF_TEXT'
              )}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeField(e.target.checked, 'is_facilitator');
              }}
              checked={(user as LDUser).is_facilitator}
              onText={intl.get(
                'SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.TOGGLE_ON_TEXT'
              )}
              className='m-0 text-neutral-black text-sm font-semibold'
              labelProps={{
                className: 'mb-5',
              }}
              isSmall
              id='make-facilitator-toggle'
              inputProps={{
                'data-testid': 'is-facilitator_toggle',
              }}
            />
          </>
        )}
      </div>
      <FormItem
        label={intl.get('SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.FIRST_NAME')}
        data-cy='label__first-name'
        labelProps={{
          required: true,
          state: errors.firstName ? 'error' : 'default',
        }}
        helpText={
          errors.firstName &&
          intl.get('SETTINGS_PAGE.USER_PAGE.ERRORS.MISSING_INFORMATION')
        }
        helpTextProps={{ state: errors.firstName ? 'error' : 'default' }}
        className='mt-2'
      >
        <TextField
          variant='text'
          length='medium'
          placeholder={intl.get(
            'SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.FIRST_NAME_PLACEHOLDER'
          )}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeField(e.target.value, 'data.firstName');
            setCurrentUserData((userData) => {
              return {
                ...userData,
                data: {
                  ...userData.data,
                  firstName: e.target.value,
                },
              };
            });
          }}
          defaultValue={firstName}
          data-cy='field__first-name'
          state={errors.firstName ? 'error' : 'default'}
        />
      </FormItem>
      <FormItem
        label={intl.get('SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.LAST_NAME')}
        data-cy='label__last-name'
        labelProps={{
          required: true,
          state: errors.lastName ? 'error' : 'default',
        }}
        helpText={
          errors.lastName &&
          intl.get('SETTINGS_PAGE.USER_PAGE.ERRORS.MISSING_INFORMATION')
        }
        helpTextProps={{ state: errors.lastName ? 'error' : 'default' }}
        className='mt-2'
      >
        <TextField
          variant='text'
          length='medium'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeField(e.target.value, 'data.lastName');
            setCurrentUserData((userData) => {
              return {
                ...userData,
                data: {
                  ...userData.data,
                  lastName: e.target.value,
                },
              };
            });
          }}
          placeholder={intl.get(
            'SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.LAST_NAME_PLACEHOLDER'
          )}
          defaultValue={lastName}
          data-cy='field__last-name'
          state={errors.lastName ? 'error' : 'default'}
        />
      </FormItem>
      <FormItem
        label={intl.get('SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.EMAIL')}
        data-cy='label__email'
        labelProps={{
          required: true,
          state: errors.email ? 'error' : 'default',
        }}
        helpText={
          errors.email &&
          intl.get('SETTINGS_PAGE.USER_PAGE.ERRORS.MISSING_INFORMATION')
        }
        helpTextProps={{ state: errors.email ? 'error' : 'default' }}
        className='mt-4 col-start-1 col-end-1 col-span-2'
      >
        <TextField
          variant='text'
          length='medium'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeField(e.target.value, 'data.email');
          }}
          defaultValue={email}
          data-cy='field__email'
          state={errors.email ? 'error' : 'default'}
          placeholder={intl.get(
            'SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.EMAIL_PLACEHOLDER'
          )}
          disabled={user?.id}
          readOnly={isProfileEdit}
        />
      </FormItem>
      <FormItem
        label={intl.get('SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.COUNTRY')}
        labelProps={{
          required: true,
          state: errors.country ? 'error' : 'default',
        }}
        helpText={
          errors.country &&
          intl.get('SETTINGS_PAGE.USER_PAGE.ERRORS.MISSING_INFORMATION')
        }
        helpTextProps={{ state: errors.country ? 'error' : 'default' }}
        data-cy='label__country'
        className='mt-4 col-start-1 col-end-1 col-span-2'
      >
        <Dropdown
          options={countryOptions}
          filterable
          values={[
            {
              label: intl.get(`COUNTRIES.${country}`),
              value: country,
            },
          ]}
          triggerProps={{
            className: 'h-6',
            'data-cy': 'field__country',
            placeholder: intl.get(
              'SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.COUNTRY_PLACEHOLDER'
            ),
          }}
          onChange={(option: Option) => {
            setCountry(option.value);
            setProvince('');
            handleChangeField(option.value, 'country_iso_3166_1_alpha_2_code');
            handleChangeField('', 'data.province');
          }}
          state={errors.country ? 'error' : 'default'}
        />
      </FormItem>
      <FormItem
        label={intl.get('SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.PROVINCE')}
        className='mt-4'
      >
        {provinceOptions.length > 0 ? (
          <Dropdown
            controlled
            filterable
            values={[
              {
                label: province,
                value: province,
              },
            ]}
            triggerProps={{
              className: 'h-6',
              'data-cy': 'field__province',
              placeholder: intl.get(
                'SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.PROVINCE_LIST_PLACEHOLDER'
              ),
            }}
            options={provinceOptions}
            onChange={(option: Option) => {
              setProvince(option.value);
              handleChangeField(option.value, 'data.province');
            }}
          />
        ) : (
          <TextField
            variant='text'
            length='medium'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeField(e.target.value, 'data.province');
            }}
            placeholder={intl.get(
              'SETTINGS_PAGE.USER_PAGE.BASIC_INFORMATION.PROVINCE_TEXT_PLACEHOLDER'
            )}
            defaultValue={province}
            data-cy='field__province'
          />
        )}
      </FormItem>
    </div>
  );
};

export default BasicInformation;
