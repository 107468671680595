import { FC } from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import {
  FormItem,
  tailwindOverride,
  TextField,
  Typography,
} from '@getsynapse/design-system';
import { selectTaskActualHoursSum } from 'state/TaskDetail/taskSlice';

interface TaskActualHoursFieldProps {
  isOnSidepanel?: boolean;
  displayModal?: () => void;
}

const TaskActualHoursField: FC<TaskActualHoursFieldProps> = ({
  isOnSidepanel = false,
  displayModal = () => {},
}) => {
  const taskActualHoursSum = useSelector(selectTaskActualHoursSum);

  return (
    <div>
      <FormItem
        component='div'
        label={
          <Typography className='text-label leading-4 font-semibold mr-1'>
            {intl.get('TASKS.TASK_DETAIL_PAGE.ACTUAL_HOURS')}
          </Typography>
        }
        labelProps={{ htmlFor: 'actual-hours-field' }}
        className={tailwindOverride({ 'mt-4': isOnSidepanel })}
      >
        <TextField
          id='actual-hours-field'
          value={`${taskActualHoursSum}h`}
          aria-label='task-actual-hours-field'
          divProps={{ className: 'flex-1' }}
          readOnly
          onClick={displayModal}
        />
      </FormItem>
    </div>
  );
};

export default TaskActualHoursField;
