import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import {
  NewScheduleCreationParams,
  ScheduleUpdateParams,
  SearchFilterParams,
  ExceptionCreationParams,
  FetchedEventsWithProjects,
} from 'utils/types/learningSchedule';

class ScheduleAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(
      `${config.get('backendURL')}v2/facilitation-management/`
    );
  }

  createSchedule = async (newScheduleData: NewScheduleCreationParams) => {
    const res = await this.instance.post('event', newScheduleData);
    return res;
  };

  createException = async (params: ExceptionCreationParams) => {
    const res = await this.instance.post('event', params.data);
    return res;
  };

  updateSchedule = async (
    scheduleId: string,
    updateScheduleData: ScheduleUpdateParams
  ) => {
    const res = await this.instance.put(
      `event/${scheduleId}`,
      updateScheduleData
    );
    return res;
  };

  fetchEvents = async (params: SearchFilterParams) => {
    let url = 'event/get_events_by_projects';
    const queryParams = [];

    if (params.search) {
      queryParams.push(`search=${params.search}`);
    }

    if (params.filters) {
      const { projects, facilitators, eventTypes, unassignedSchedules } =
        params.filters;

      if (projects?.length > 0) {
        queryParams.push(`projects=${projects.join(',')}`);
      }
      if (facilitators?.length > 0) {
        queryParams.push(`facilitators=${facilitators.join(',')}`);
      }
      if (eventTypes?.length > 0) {
        queryParams.push(`event_types=${eventTypes.join(',')}`);
      }
      if (unassignedSchedules) {
        queryParams.push(`unassigned_schedules=${unassignedSchedules}`);
      }
    }
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    const res = await this.instance.get(url);
    return res.data as FetchedEventsWithProjects;
  };

  fetchMySchedule = async () => {
    const res = await this.instance.get('event/my_schedule');
    return res.data;
  };

  deleteSchedule = async (scheduleId: string) => {
    const res = await this.instance.delete(`event/${scheduleId}`);
    return res;
  };
}

export default new ScheduleAPI();
