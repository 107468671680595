import { FC, useState, useEffect, useRef } from 'react';
import { TableOperationHeader } from '@getsynapse/design-system';
import debounce from 'lodash/debounce';
import { DebouncedFunc } from 'lodash';

interface SearchFilterProps {
  initialSearchFilterValue: string;
  onSearchFilterChange: (value: string) => void;
  tableName?: string;
}

const SearchFilter: FC<SearchFilterProps> = ({
  children,
  initialSearchFilterValue,
  onSearchFilterChange,
  tableName,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValueChangeHandler = useRef<DebouncedFunc<
    (value: string) => void
  > | null>(null);

  const handleSearchValueChange = (value: string) => {
    setSearchValue(value);
    if (debouncedSearchValueChangeHandler.current) {
      debouncedSearchValueChangeHandler.current(value);
    }
  };

  useEffect(() => {
    debouncedSearchValueChangeHandler.current = debounce((value: string) => {
      onSearchFilterChange(value);
    }, 500);

    return () => {
      if (debouncedSearchValueChangeHandler.current) {
        debouncedSearchValueChangeHandler.current.cancel();
      }
    };
  }, [onSearchFilterChange]);

  useEffect(() => {
    setSearchValue(initialSearchFilterValue);
  }, [initialSearchFilterValue]);

  return (
    <TableOperationHeader
      className='border-b-0'
      inputValue={searchValue}
      setInputValue={handleSearchValueChange}
      actionButtons={children}
      searchProps={{
        'aria-label': `${tableName}__search-filter`,
      }}
    />
  );
};

export default SearchFilter;
