import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  selectTaskDeleted,
  setTaskDeleted,
  selectFailedTaskReorder,
  setFailedTaskReorder,
} from 'state/ProjectTasksList/actions/actionsSlice';
import useSnackbarNotification from 'Hooks/useSnackbarNotification';

const ActionsNotifications = () => {
  const dispatch = useDispatch();
  const taskDeleted = useSelector(selectTaskDeleted);
  const failedTaskReorder = useSelector(selectFailedTaskReorder);

  const {
    SnackbarNotification,
    snackbarProps,
    showNotification,
    isNotificationVisible,
  } = useSnackbarNotification();

  useEffect(() => {
    if (taskDeleted) {
      if (!isNotificationVisible) {
        showNotification({
          notificationTitle: '',
          notificationMessage: intl.get(
            'TASKS.TASK_DETAIL_PAGE.DELETE_SUCCESS'
          ),
        });
      } else {
        dispatch(setTaskDeleted(null));
      }
    }
  }, [taskDeleted, dispatch, isNotificationVisible, showNotification]);

  useEffect(() => {
    if (failedTaskReorder) {
      if (!isNotificationVisible) {
        showNotification({
          notificationVariant: 'error',
          notificationTitle: intl.get(
            'TASKS.NOTIFICATIONS.REORDER_FAILED.TITLE'
          ),
          notificationMessage: intl.get(
            'TASKS.NOTIFICATIONS.REORDER_FAILED.MESSAGE'
          ),
        });
      } else {
        dispatch(setFailedTaskReorder(false));
      }
    }
  }, [failedTaskReorder, dispatch, isNotificationVisible, showNotification]);

  if (!isNotificationVisible) {
    return null;
  }

  return <SnackbarNotification title='Project actions' {...snackbarProps} />;
};

export default ActionsNotifications;
