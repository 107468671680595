import intl from 'react-intl-universal';
import {
  SidePanelFilter,
  FormItem,
  UsersPicker,
  Dropdown,
} from '@getsynapse/design-system';
import useEventsFilters from 'Pages/LearningSchedule/hooks/useEventsFilters';
import useSidePanelFilters from 'Hooks/useSidePanelFilters';
import { EventFilters, EventFiltersKey } from 'utils/types/filters';
import { Option, UserOption } from 'utils/customTypes';
import { EVENTS_TABLE_FILTERS } from 'utils/constants/learningSchedule';
import {
  getInitialValueForDropDown,
  getInitialUsersPickerValue,
} from 'utils/functions';

type EventsFiltersSidePanelProps = {
  open: boolean;
  onUpdateFilters: (filters: EventFilters) => void;
  onClose: () => void;
  savedFilters: EventFilters;
};

const EventsFiltersSidePanel = ({
  open,
  onUpdateFilters,
  onClose,
  savedFilters,
}: EventsFiltersSidePanelProps) => {
  const { projectOptions, facilitatorOptions, eventTypesOptions } =
    useEventsFilters();

  const {
    appliedFilters,
    canApplyFilters,
    handleUpdateFilters,
    handleApplyFilters,
    handleClearAllFilters,
    handleClosePanel,
  } = useSidePanelFilters<EventFilters>(savedFilters, onUpdateFilters, onClose);

  const updateFilters = (key: EventFiltersKey, value: Option[]) => {
    const updatedFilters = { ...appliedFilters };
    if (value.length === 0) {
      delete updatedFilters[key];
    } else {
      updatedFilters[key] = value.map((option) => option.value);
    }
    handleUpdateFilters(updatedFilters);
  };

  return (
    <SidePanelFilter
      open={open}
      onClose={handleClosePanel}
      appliedFiltersCount={Object.keys(appliedFilters).length}
      canApplyFilters={canApplyFilters}
      onClearAllFilters={handleClearAllFilters}
      onApplyFilters={handleApplyFilters}
    >
      <FormItem
        label={intl.get('SCHEDULE.EVENTS.FILTERS.FACILITATORS.LABEL')}
        className='mb-6 mt-4'
      >
        <UsersPicker
          triggerText={intl.get(
            'SCHEDULE.EVENTS.FILTERS.FACILITATORS.TRIGGER_TEXT'
          )}
          usersList={facilitatorOptions}
          selectedUsersList={getInitialUsersPickerValue(
            facilitatorOptions,
            (appliedFilters as EventFilters)[
              EVENTS_TABLE_FILTERS.FACILITATORS
            ] as string[]
          )}
          onChange={(selectedUsers: UserOption[]) =>
            updateFilters(EVENTS_TABLE_FILTERS.FACILITATORS, selectedUsers)
          }
          noSelectedUsersText={intl.get(
            'SCHEDULE.EVENTS.FILTERS.FACILITATORS.NO_FACILITATORS_SELECTED'
          )}
          selectedUsersListTitle={intl.get(
            'SCHEDULE.EVENTS.FILTERS.FACILITATORS.SELECTED_FACILITATORS'
          )}
          emptyUsersListText={intl.get(
            'SCHEDULE.EVENTS.FILTERS.FACILITATORS.NO_AVAILABLE_FACILITATORS'
          )}
          triggerProps={{ 'data-testid': 'facilitators_trigger' }}
          popperProps={{ 'data-testid': 'facilitators_popper' }}
        />
      </FormItem>
      <FormItem
        label={intl.get('SCHEDULE.EVENTS.FILTERS.EVENT_TYPE_LABEL')}
        className='mb-6'
      >
        <Dropdown
          options={eventTypesOptions}
          onChange={(options: Option[]) =>
            updateFilters(EVENTS_TABLE_FILTERS.EVENT_TYPE, options)
          }
          multiple
          values={getInitialValueForDropDown(
            eventTypesOptions,
            (appliedFilters as EventFilters)[
              EVENTS_TABLE_FILTERS.EVENT_TYPE
            ] as string[]
          )}
        />
      </FormItem>
      <FormItem label={intl.get('SCHEDULE.EVENTS.FILTERS.PROJECT_LABEL')}>
        <Dropdown
          options={projectOptions}
          onChange={(options: Option[]) =>
            updateFilters(EVENTS_TABLE_FILTERS.PROJECTS, options)
          }
          multiple
          values={getInitialValueForDropDown(
            projectOptions,
            (appliedFilters as EventFilters)[
              EVENTS_TABLE_FILTERS.PROJECTS
            ] as string[]
          )}
          triggerProps={{ 'data-testid': 'projects-dropdown_trigger' }}
          listProps={{
            'data-testid': 'projects-dropdown_list',
          }}
        />
      </FormItem>
    </SidePanelFilter>
  );
};

export default EventsFiltersSidePanel;
