import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableOperationHeader } from '@getsynapse/design-system';
import {
  selectFiltersSettingsByType,
  updateFilterSetting,
  createFilterSetting,
} from 'state/Settings/Filters/FiltersSlice';
import { setFilters } from 'state/Schedule/ScheduleSlice';
import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';
import {
  updateSearchTerm,
  selectSearchTerm,
} from 'state/Schedule/ScheduleSlice';
import debounce from 'lodash/debounce';
import { SETTINGS_FILTERS } from 'utils/constants';
import AppliedFiltersTags from 'Organisms/AppliedFiltersTags/AppliedFiltersTags';
import useEventsFilters from 'Pages/LearningSchedule/hooks/useEventsFilters';
import { EventFilters } from 'utils/types/filters';
import EventsFiltersSidePanel from './EventsFiltersSidePanel/EventsFiltersSidePanel';

const EventsHeader = () => {
  const dispatch = useDispatch();
  const search = useSelector(selectSearchTerm);
  const [searchTerm, setSearchTerm] = useState(search);

  const filtersSettings = useSelector(
    selectFiltersSettingsByType(SETTINGS_FILTERS.SCHEDULE_EVENTS_VIEW)
  );

  const debouncedHandleSearch = useMemo(() => {
    return debounce((value: string) => {
      dispatch(updateSearchTerm({ search: value }));
    }, 500);
  }, [dispatch]);

  const savedFilters = useMemo(
    () => (filtersSettings ? filtersSettings.settings : {}),
    [filtersSettings]
  );

  const { getOptionsbyKey } = useEventsFilters();

  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);

  const toggleFilterPanel = () => {
    setIsFilterPanelOpen((prevState) => !prevState);
  };

  const saveFilters = (updatedFilters: EventFilters) => {
    if (filtersSettings && filtersSettings.id) {
      dispatch(
        updateFilterSetting({
          id: filtersSettings.id,
          updateFields: { filter_settings: updatedFilters },
        })
      );
    } else {
      dispatch(
        createFilterSetting({
          filter_type: SETTINGS_FILTERS.SCHEDULE_EVENTS_VIEW,
          filter_settings: updatedFilters,
        })
      );
    }
  };

  const handleUpdateFilters = (updatedFilters: EventFilters) => {
    dispatch(setFilters(updatedFilters));
    saveFilters(updatedFilters);
  };

  return (
    <>
      <EventsFiltersSidePanel
        savedFilters={savedFilters}
        onClose={() => setIsFilterPanelOpen(false)}
        open={isFilterPanelOpen}
        onUpdateFilters={handleUpdateFilters}
      />
      <TableOperationHeader
        className='border-b-0'
        actionButtons={
          <FilterButton
            data-testid='events-filter_button'
            onClick={() => setIsFilterPanelOpen(true)}
          />
        }
        inputValue={searchTerm}
        setInputValue={(value: string) => {
          setSearchTerm(value);
          debouncedHandleSearch(value);
        }}
      />
      <AppliedFiltersTags<EventFilters>
        savedFilters={savedFilters}
        onUpdateFilters={handleUpdateFilters}
        onDisplayAllFilters={toggleFilterPanel}
        getOptionsbyKey={getOptionsbyKey}
        dataTestid='events-applied-filters'
      />
    </>
  );
};

export default EventsHeader;
