import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  ScheduleComponent,
  Inject,
  CellClickEventArgs,
  EventRenderedArgs,
  WorkWeek,
} from '@syncfusion/ej2-react-schedule';
import { Internationalization } from '@syncfusion/ej2-base';
import {
  fetchMySchedule,
  selectMyScheduleEvents,
} from 'state/Schedule/ScheduleSlice';
import { SCHEDULE_TABS } from 'utils/constants/learningSchedule';
import {
  headerTemplate,
  ContentTemplate,
} from '../../../Events/components/EventQuickView/EventQuickView';
import { colors } from 'Pages/LearningSchedule/constants/constants';
import '../../styles.css';

const MyScheduleCalendar = ({ pageToLoad }: { pageToLoad: string }) => {
  const dispatch = useDispatch();
  const events = useSelector(selectMyScheduleEvents);
  const scheduleObj = useRef<ScheduleComponent>(null);

  const [eventsColors, setEventsColors] = useState<{ [key: string]: string }>(
    {}
  );
  const [colorIndex, setColorIndex] = useState<number>(0);

  useEffect(() => {
    if (pageToLoad === SCHEDULE_TABS.MY_SCHEDULE) {
      dispatch(fetchMySchedule());
    }
  }, [pageToLoad, dispatch]);

  const intlInstance = useMemo(() => {
    return new Internationalization();
  }, []);

  const closeQuickInfo = useCallback(() => {
    if (
      scheduleObj &&
      scheduleObj.current &&
      scheduleObj.current.closeQuickInfoPopup
    ) {
      scheduleObj.current.closeQuickInfoPopup();
    }
  }, [scheduleObj]);

  const formatHeader = useCallback(
    (args: { date: Date }) => {
      const formattedDate = intlInstance.formatDate(args?.date, {
        skeleton: 'Ed',
      });
      return <span>{formattedDate}</span>;
    },
    [intlInstance]
  );

  const onCellClick = (args: CellClickEventArgs) => {
    args.cancel = true;
  };

  const applyCategoryColor = (args: EventRenderedArgs) => {
    if (!args.element) return;

    const { Id } = args.data;

    if (eventsColors[Id]) {
      args.element.style.backgroundColor = eventsColors[Id];
      return;
    }

    const categoryColor = colors[colorIndex];
    args.element.style.backgroundColor = categoryColor;

    setEventsColors((prev) => {
      return {
        ...prev,
        [args.data.Id]: categoryColor,
      };
    });
    setColorIndex((prev) => (prev + 1) % colors.length);
  };

  return (
    <div
      className='h-my-schedule overflow-y-auto'
      data-testid='my-schedule_container'
      id='my-schedule'
    >
      <ScheduleComponent
        height='100%'
        ref={scheduleObj}
        eventSettings={{
          dataSource: events,
          allowEditing: false,
          allowAdding: false,
        }}
        currentView='WorkWeek'
        views={['WorkWeek']}
        showTimeIndicator={false}
        rowAutoHeight={true}
        dateHeaderTemplate={formatHeader}
        cellClick={(args) => onCellClick(args)}
        eventDoubleClick={(args) => (args.cancel = true)}
        quickInfoTemplates={{
          header: headerTemplate(closeQuickInfo),
          content: ContentTemplate,
          footer: () => <></>,
        }}
        eventRendered={applyCategoryColor}
      >
        <Inject services={[WorkWeek]} />
      </ScheduleComponent>
    </div>
  );
};

export default MyScheduleCalendar;
