import { FC, useState } from 'react';
import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';
import { TaskDetailToUpdate, TaskDetailProject } from 'types/store/taskDetail';
import { UserOption } from '@getsynapse/design-system/dist/Molecules/UsersPicker/UsersPicker';
import { rangeDate } from 'utils/customTypes';
import { TASK_STATUS } from 'utils/constants';
import TaskName from './fields/TaskName';
import TaskDescription from './fields/TaskDescription';
import ProjectLink from './fields/TaskProjectLink';
import TaskAssigneesField from './fields/TaskAssignee';
import TaskDatesField from './fields/TaskDates';
import TaskTypeField from './fields/TaskType';
import TaskStatusField from './fields/TaskStatus';
import TaskActualCompletionDateField from './fields/TaskActualHourCompletionDate';
import TaskEstimatedTimeField from './fields/TaskEstimateHours';
import TaskActualHoursField from './fields/TaskActualHours';
import TaskActualHoursModal from '../ActualHoursModal/ActualHoursModal';

interface TaskDetailFormProps {
  task: TaskDetailToUpdate;
  requiredFieldsErrors: Map<string, boolean>;
  project: TaskDetailProject;
  hasAssignedUsers: boolean;
  canUpdateTask?: boolean;
  isTaskCompleted?: boolean;
  isReadOnly?: boolean;
  isViewOnly?: boolean;
  updateTaskDetail: (
    key: keyof TaskDetailToUpdate,
    value: string | undefined
  ) => void;
  updateTaskDateRange: (range: rangeDate) => void;
  updateTaskAssignees: (assignees: UserOption[]) => void;
  updateTaskStatus: (status: string) => void;
}

const TaskDetailForm: FC<TaskDetailFormProps> = ({
  task,
  requiredFieldsErrors,
  project,
  hasAssignedUsers,
  canUpdateTask = true,
  isReadOnly = false,
  isViewOnly = false,
  isTaskCompleted = false,
  updateTaskDetail,
  updateTaskDateRange,
  updateTaskAssignees,
  updateTaskStatus,
}) => {
  const [displayActualHoursModal, setDisplayActualHoursModal] =
    useState<boolean>(false);

  const toggleActualHoursModal = () => {
    setDisplayActualHoursModal(!displayActualHoursModal);
  };

  const canAddActualHours = canUpdateTask && !isTaskCompleted && !isViewOnly;

  return (
    <>
      <TaskActualHoursModal
        taskId={task.id}
        canAddActualHours={canAddActualHours}
        shouldDisplayModal={displayActualHoursModal}
        toggleDisplayModal={toggleActualHoursModal}
      />
      <div className='mt-4 border-b border-neutral-lighter pb-10'>
        <Typography variant='h5'>
          {intl.get('TASKS.TASK_DETAIL_PAGE.TITLE')}
        </Typography>
        <div className='grid-cols-2 w-full grid gap-x-10% gap-y-4 px-px mt-8'>
          <TaskName
            name={task.name}
            isReadOnly={isReadOnly}
            isViewOnly={isViewOnly}
            hasError={requiredFieldsErrors.get('name') ?? false}
            onNameChange={(name: string) => {
              updateTaskDetail('name', name);
            }}
          />
          <ProjectLink projectId={project.id} projectTitle={project.title} />
          <TaskDescription
            description={task.description}
            isReadOnly={isReadOnly}
            isViewOnly={isViewOnly}
            onDescriptionChange={(description: string) => {
              updateTaskDetail('description', description);
            }}
          />
          <TaskAssigneesField
            taskId={task.id}
            onAssigneesChange={updateTaskAssignees}
            isReadOnly={isReadOnly}
            isViewOnly={isViewOnly}
          />
          <TaskDatesField
            isReadOnly={isReadOnly}
            isViewOnly={isViewOnly}
            hasAssignedUsers={hasAssignedUsers}
            startDate={task.startDate ? new Date(task.startDate) : undefined}
            dueDate={task.dueDate ? new Date(task.dueDate) : undefined}
            onDateChange={updateTaskDateRange}
            hasError={requiredFieldsErrors.get('startDate') ?? false}
          />
          <TaskTypeField
            type={task.type}
            onChangeType={(type: string) => {
              updateTaskDetail('type', type);
            }}
            isReadOnly={isReadOnly}
            isViewOnly={isViewOnly}
          />
          <div className='flex'>
            <TaskStatusField
              status={task.status}
              disabled={
                task.status === TASK_STATUS.ON_HOLD
                  ? !canUpdateTask
                  : isReadOnly || task.disabled
              }
              hasAssignedUsers={hasAssignedUsers}
              onStatusChange={(status: string) => {
                updateTaskStatus(status);
              }}
            />
            {task?.status === TASK_STATUS.COMPLETED && (
              <TaskActualCompletionDateField
                completionDate={
                  task.completionDate
                    ? new Date(task.completionDate)
                    : undefined
                }
                onCompletionDateChange={(date: string | undefined) => {
                  updateTaskDetail('completionDate', date);
                }}
                isReadOnly={isReadOnly}
                isViewOnly={isViewOnly}
                hasError={requiredFieldsErrors.get('completionDate') ?? false}
              />
            )}
          </div>
          <TaskEstimatedTimeField
            estimateHours={task.estimateHours}
            onEstimateHoursChange={(estimateHours: string) => {
              updateTaskDetail('estimateHours', estimateHours);
            }}
            isReadOnly={isReadOnly}
            isViewOnly={isViewOnly}
          />
          <TaskActualHoursField displayModal={toggleActualHoursModal} />
        </div>
      </div>
    </>
  );
};

export default TaskDetailForm;
