import { useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TabPanel, TabPanels, Tabs } from '@reach/tabs';
import intl from 'react-intl-universal';
import { Tab, TabList, Button } from '@getsynapse/design-system';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import {
  isUserFacilitator,
  isUserOrganizationAdmin,
} from 'state/User/userSlice';
import { SCHEDULE_TABS } from 'utils/constants/learningSchedule';
import Events from './components/Events/Events';
import { EventsScheduleRef } from './components/Events/components/EventsSchedule/EventsSchedule';
import MySchedule from './components/MySchedule/MySchedule';

const LearningSchedule = () => {
  const isFacilitator = useSelector(isUserFacilitator);
  const isAdmin = useSelector(isUserOrganizationAdmin);

  const SCHEDULE_TABS_MAPPING: { [key: string]: number | undefined } = useMemo(
    () =>
      isFacilitator
        ? {
            [SCHEDULE_TABS.MY_SCHEDULE]: 0,
            [SCHEDULE_TABS.EVENTS]: 1,
          }
        : {
            [SCHEDULE_TABS.MY_SCHEDULE]: undefined,
            [SCHEDULE_TABS.EVENTS]: 0,
          },
    [isFacilitator]
  );

  const queryParams = new URLSearchParams(window.location.search);
  const activeTab = queryParams.get('activeTab');

  const [currentTabIndex, setCurrentTabIndex] = useState(
    activeTab ? SCHEDULE_TABS_MAPPING[activeTab] : 0
  );

  const pageToLoad = useMemo(() => {
    if (isFacilitator && !isAdmin) {
      return SCHEDULE_TABS.MY_SCHEDULE;
    }

    if (isAdmin && !isFacilitator) {
      return currentTabIndex === 0
        ? SCHEDULE_TABS.EVENTS
        : SCHEDULE_TABS.FACILITATORS;
    }

    if (isAdmin && isFacilitator) {
      switch (currentTabIndex) {
        case 0:
          return SCHEDULE_TABS.MY_SCHEDULE;
        case 1:
          return SCHEDULE_TABS.EVENTS;
        case 2:
          return SCHEDULE_TABS.FACILITATORS;
      }
    }
  }, [currentTabIndex, isFacilitator, isAdmin]);

  const eventsScheduleRef = useRef<EventsScheduleRef>(null);

  const onAddingEvent = () => {
    eventsScheduleRef?.current?.onAddingEvent();
  };

  const handleChangeTab = (index: number) => {
    if (index === currentTabIndex) return;
    setCurrentTabIndex(index);
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?activeTab=${
        Object.keys(SCHEDULE_TABS_MAPPING)[index]
      }`
    );
  };

  return (
    <>
      <PageTitle titleComponent={intl.get('SCHEDULE.TITLE')} />
      {isFacilitator && !isAdmin && (
        <div className='mx-6'>
          <MySchedule pageToLoad={pageToLoad!} />
        </div>
      )}
      {isAdmin && (
        <Tabs
          index={currentTabIndex}
          onChange={handleChangeTab}
          className='mx-6'
        >
          {({ selectedIndex }) => (
            <>
              <div className='flex justify-between'>
                <TabList className='w-64 mb-4'>
                  {isFacilitator && (
                    <Tab index={0}>{intl.get('SCHEDULE.TABS.MY_SCHEDULE')}</Tab>
                  )}
                  <Tab index={isFacilitator ? 1 : 0}>
                    {intl.get('SCHEDULE.TABS.EVENTS')}
                  </Tab>
                </TabList>
                {((isFacilitator && selectedIndex === 1) ||
                  (!isFacilitator && selectedIndex === 0)) && (
                  <Button
                    className='h-8'
                    data-testid='add-event_button'
                    onClick={onAddingEvent}
                  >
                    {intl.get('SCHEDULE.ADD_EVENT_BUTTON')}
                  </Button>
                )}
              </div>

              <TabPanels>
                {isFacilitator && (
                  <TabPanel key={0}>
                    <MySchedule pageToLoad={pageToLoad!} />
                  </TabPanel>
                )}
                <TabPanel key={isFacilitator ? 1 : 0}>
                  <Events ref={eventsScheduleRef} pageToLoad={pageToLoad!} />
                </TabPanel>
              </TabPanels>
            </>
          )}
        </Tabs>
      )}
    </>
  );
};

export default LearningSchedule;
