import { FC } from 'react';
import intl from 'react-intl-universal';
import {
  TableHead,
  tailwindOverride,
  Checkbox,
  IconButton,
} from '@getsynapse/design-system';

interface TableHeaderProps {
  isListEmpty?: boolean;
  showDisabledColumn?: boolean;
}

const TableHeader: FC<TableHeaderProps> = ({
  isListEmpty = false,
  showDisabledColumn = false,
}) => {
  return (
    <TableHead
      stickyHeader={!isListEmpty}
      isSelectRowCellSticky={true}
      headCells={[
        {
          content: '',
          className: tailwindOverride('w-6 h-full lef-0'),
        },
        {
          content: (
            <div className='flex h-full gap-x-2 items-center'>
              <Checkbox
                onChange={() => {}}
                label=''
                inputProps={{
                  className: 'mr-0 mt-0',
                }}
              />
              <IconButton name='caret-down' className='text-sm text-neutral' />
            </div>
          ),
          'aria-label': 'reorder-tasks',
          className: tailwindOverride('h-full', {
            'w-12 left-6 z-2': !isListEmpty,
          }),
        },
        {
          content: '',
          'aria-label': 'disabled-completed-tasks',
          className: tailwindOverride('h-full', {
            'w-12 z-2 left-20': !isListEmpty,
            hidden: !showDisabledColumn,
          }),
        },
        {
          content: intl.get('TASKS_LIST_PAGE.TABLE.HEAD.ID'),
          className: tailwindOverride('w-32 h-full', {
            'z-2': !isListEmpty,
            'left-32': showDisabledColumn,
            'left-20': !showDisabledColumn,
            'left-0': isListEmpty,
          }),
        },
        {
          content: intl.get('TASKS.TABLE.HEAD.NAME'),
          className: tailwindOverride('w-1/3 h-full', {
            'z-2': !isListEmpty,
            'left-72': showDisabledColumn,
            'left-60': !showDisabledColumn,
            'left-0': isListEmpty,
          }),
        },
        {
          content: intl.get('TASKS.TABLE.HEAD.STATUS'),
          className: tailwindOverride('h-full', { 'w-20': !isListEmpty }),
        },
        {
          content: intl.get('TASKS.TABLE.HEAD.ASSIGNEE_UPDATE'),
          className: tailwindOverride('h-full', { 'w-44': !isListEmpty }),
        },
        {
          content: intl.get('TASKS.TABLE.HEAD.TASK_TYPE'),
          className: tailwindOverride('h-full', { 'w-31': !isListEmpty }),
        },
        {
          content: intl.get('TASKS.TABLE.HEAD.START_DATE'),
          className: tailwindOverride('h-full', { 'w-38': !isListEmpty }),
        },
        {
          content: intl.get('TASKS.TABLE.HEAD.DUE_ACTUAL_DATE'),
          className: tailwindOverride('h-full', { 'w-60': !isListEmpty }),
        },
        {
          content: intl.get('TASKS.TABLE.HEAD.ESTIMATED_ACTUAL_HOURS'),
          className: tailwindOverride('h-full', { 'w-34': !isListEmpty }),
        },
        {
          content: <div></div>,
          className: tailwindOverride('w-12 h-full z-2', {
            'right-0': !isListEmpty,
          }),
        },
      ]}
    />
  );
};

export default TableHeader;
