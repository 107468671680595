import { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { SidePanel } from '@getsynapse/design-system';
import { SLICE_STATUS, TASK_STATUS } from 'utils/constants';
import {
  selectTaskIdToEdit,
  setTaskIdToEdit,
  setShouldRefreshTasks,
} from 'state/ProjectTasksList/actions/actionsSlice';
import {
  fetchTaskDetail,
  selectCanEdit,
  selectTaskSliceStatus,
  selectIsTaskDisabled,
  selectTaskDueDate,
  selectTaskProject,
  selectTaskStatus,
} from 'state/TaskDetail/taskSlice';
import useSidePanel from 'Hooks/useSidePanel';
import useUpdateTask from 'Pages/TaskDetailPage/hooks/useUpdateTask';
import useSnackbarNotification from 'Hooks/useSnackbarNotification';
import { validateIfTaskDueDateIsPast } from 'Pages/TaskDetailPage/TaskDetailForm/helpers/helpers';
import HeaderElement from './HeaderElement/HeaderElement';
import SidePanelContent from './SidePanelContent/SidePanelContent';

const TaskDetailSidePanel = () => {
  const dispatch = useDispatch();
  const taskProject = useSelector(selectTaskProject);
  const taskDueDate = useSelector(selectTaskDueDate);
  const canUpdateTask = useSelector(selectCanEdit);
  const isTaskDisabled = useSelector(selectIsTaskDisabled);
  const fetchTaskDetailStatus = useSelector(selectTaskSliceStatus);
  const taskIdToEdit = useSelector(selectTaskIdToEdit);
  const taskStatus = useSelector(selectTaskStatus);
  const isTaskOnHold = taskStatus === TASK_STATUS.ON_HOLD;
  const isTaskCompleted = taskStatus === TASK_STATUS.COMPLETED;
  const isTaskDueDatePast = useMemo(
    () => validateIfTaskDueDateIsPast(taskDueDate),
    [taskDueDate]
  );

  const closePanelCallback = useCallback(() => {
    dispatch(setTaskIdToEdit(null));
  }, [dispatch]);

  const { openPanel, isPanelOpen, onClose, closePanel } =
    useSidePanel(closePanelCallback);

  useEffect(() => {
    if (taskIdToEdit) {
      dispatch(fetchTaskDetail({ taskId: taskIdToEdit }));
      openPanel();
    }
  }, [taskIdToEdit, dispatch, openPanel]);

  const {
    SnackbarNotification,
    snackbarProps,
    isNotificationVisible,
    showNotification,
  } = useSnackbarNotification();

  const onSuccessCallback = useCallback(async () => {
    dispatch(setShouldRefreshTasks(true));
    closePanel();
  }, [dispatch, closePanel]);

  const {
    task,
    requiredFieldsErrors,
    canSubmitChanges,
    hasAssignedUsers,
    updateTaskDetail,
    updateTaskAssignees,
    updateTaskDateRange,
    updateTaskStatus,
    handleUpdateTask,
  } = useUpdateTask({
    showNotification,
    onSuccessCallback,
  });

  const footerButtons = useMemo(
    () => [
      {
        children: intl.get('UPDATE'),
        disabled: !canUpdateTask || !canSubmitChanges,
        onClick: handleUpdateTask,
      },
      {
        children: intl.get('CANCEL'),
        variant: 'tertiary',
        onClick: closePanel,
      },
    ],
    [closePanel, canUpdateTask, handleUpdateTask, canSubmitChanges]
  );

  return (
    <>
      <SidePanel
        isOpen={isPanelOpen}
        onClose={onClose}
        footerButtons={footerButtons}
        isLoading={fetchTaskDetailStatus === SLICE_STATUS.LOADING}
        headerElement={
          <HeaderElement
            taskId={taskIdToEdit}
            projectId={taskProject?.id ?? ''}
            status={taskStatus}
            onClose={onClose}
          />
        }
      >
        {taskIdToEdit && (
          <SidePanelContent
            task={task}
            project={taskProject}
            requiredFieldsErrors={requiredFieldsErrors}
            hasAssignedUsers={hasAssignedUsers}
            canUpdateTask={canUpdateTask}
            isTaskDisabled={isTaskDisabled}
            isTaskOnHold={isTaskOnHold}
            isTaskDueDatePast={!isTaskCompleted && isTaskDueDatePast}
            updateTaskDetail={updateTaskDetail}
            updateTaskAssignees={updateTaskAssignees}
            updateTaskDateRange={updateTaskDateRange}
            updateTaskStatus={updateTaskStatus}
          />
        )}
      </SidePanel>
      {isNotificationVisible && snackbarProps && (
        <SnackbarNotification {...snackbarProps} />
      )}
    </>
  );
};

export default TaskDetailSidePanel;
