import {
  FormItem,
  NumericInput,
  tailwindOverride,
} from '@getsynapse/design-system';
import intl from 'react-intl-universal';

interface TaskEstimatedTimeFieldProps {
  estimateHours?: string;
  onEstimateHoursChange: (value: string) => void;
  isOnSidepanel?: boolean;
  isViewOnly?: boolean;
  isReadOnly?: boolean;
}
const TaskEstimatedTimeField = ({
  isOnSidepanel = false,
  isViewOnly = false,
  isReadOnly = false,
  estimateHours,
  onEstimateHoursChange,
}: TaskEstimatedTimeFieldProps) => {
  const handleOnEstimateHoursChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onEstimateHoursChange(event.target.value);
  };

  return (
    <FormItem
      component='div'
      label={intl.get('TASKS.TASK_DETAIL_PAGE.ESTIMATED_TIME_LABEL')}
      className={tailwindOverride({ 'mt-4': isOnSidepanel })}
    >
      <div className='flex items-center'>
        <NumericInput
          disabled={isViewOnly || isReadOnly}
          value={estimateHours}
          placeholder={intl.get(
            'TASKS.TASK_DETAIL_PAGE.ESTIMATED_TIME_PLACEHOLDER'
          )}
          divProps={{ className: 'flex-1' }}
          containerClassName='w-full'
          allowNegativeValue={false}
          allowFloatValue={true}
          maxAllowedDecimalPlaces={2}
          step='any'
          onChange={handleOnEstimateHoursChange}
          aria-label='task-estimated-hours-field'
        />
        <span className='pl-2'>
          {intl.get('TASKS.TASK_DETAIL_PAGE.HOURS_LABEL')}
        </span>
      </div>
    </FormItem>
  );
};

export default TaskEstimatedTimeField;
