import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import Auth from '@aws-amplify/auth';
import {
  getSelectedUser,
  selectSelectedUser,
  resetSelectedUser,
  selectUserStatus,
  updateUser,
  assignUserToLearningTeam,
  addUser,
  selectAvailableLicenses,
  inviteUser,
} from 'state/UsersManagement/usersManagementSlice';
import UserDetailsSection from './components/UserDetailsSection';
import {
  PATHS,
  SLICE_STATUS,
  USER_STATUS,
  USER_TYPES,
  LICENSE_TIER,
  SETTINGS_TABS,
} from 'utils/constants';
import Loader from 'Molecules/Loader/Loader';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import intl from 'react-intl-universal';
import { get, set, isEqual } from 'lodash';
import UserPageHeader from './UserPageHeader';
import UserPageFooter from './UserPageFooter';
import { AllUsersType, LDUser, objKeyAsString } from 'utils/customTypes';
import { validateRequiredFields } from './helpers/validationHelper';
import {
  displayNotification,
  setNotificationText,
  setNotificationVariant,
  setNotificationTimeout,
} from 'state/InlineNotification/inlineNotificationSlice';
import { selectOrganizationId } from 'state/User/userSlice';
import { selectOrganizationLicense } from 'state/Organization/organizationSlice';
import { License } from 'utils/customTypes';
import NotifyUserModal from './components/NotifyUserModal';
import DetailsPage from 'Molecules/DetailsPage/DetailsPage';
import UnsavedChangesPrompt from 'Organisms/UnsavedChangesPrompt/UnsavedChangesPrompt';

const UserPage = () => {
  const history = useHistory();
  const { userId, userType } = useParams<{
    userId: string;
    userType: 'business' | 'ld' | 'external' | undefined;
  }>();
  const dispatch = useDispatch();
  const userSelector = useSelector(selectSelectedUser);
  const userStatus = useSelector(selectUserStatus);
  const organizationId = useSelector(selectOrganizationId);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [userUpdatedFields, setUserUpdatedFields] = useState<
    Partial<AllUsersType>
  >({});
  const [errors, setErrors] = useState<objKeyAsString>({});
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);
  const [newUserId, setNewUserId] = useState('');
  const userRole: 'user' | 'admin' | undefined = 'user';
  const newUser = userType ? true : false;
  const LicenseData: License = useSelector(selectOrganizationLicense);
  const availableLicenses = useSelector(selectAvailableLicenses);
  const user = useMemo(
    () =>
      !newUser
        ? userSelector
        : {
            data: {
              firstName: '',
              lastName: '',
              email: userId,
            },
            type: userType,
            status:
              userType === USER_TYPES.L_D &&
              LicenseData.license_tier !== LICENSE_TIER.TRIAL &&
              availableLicenses < 1
                ? USER_STATUS.INVITED_DISABLED
                : USER_STATUS.INVITED,
            role: userRole,
            organization_id: organizationId,
            public_holidays_in_capacity_enabled: false,
          },
    [
      userId,
      newUser,
      userSelector,
      organizationId,
      userType,
      LicenseData,
      availableLicenses,
    ]
  );

  const userCurrentData = useMemo(
    () => ({
      ...user,
      ...userUpdatedFields,
      data: {
        ...user.data,
        ...userUpdatedFields.data,
      },
    }),
    [user, userUpdatedFields]
  );

  const pageTitle = newUser
    ? intl.get('ADD_USER.ADD_USER_MODAL.TITLE', {
        op: userType !== USER_TYPES.EXTERNAL ? 1 : 2,
        userType: intl
          .get(`USERS_PAGE.TABLE.USER_TYPE.${userType?.toUpperCase()}`)
          .toLowerCase(),
      })
    : `${intl.get('SETTINGS_PAGE.USER_PAGE.TITLE')}
  /${get(user, 'data.firstName')} ${get(user, 'data.lastName')}`;

  useEffect(() => {
    if (userId && !userType) {
      dispatch(getSelectedUser(userId));
    }
    return () => {
      dispatch(resetSelectedUser());
    };
  }, [userId, dispatch, userType]);

  useEffect(() => {
    const userChanged = !isEqual(user, userCurrentData);
    const fieldsUpdated = Object.keys(userUpdatedFields).length > 0;
    const hasUpdates = newUser ? fieldsUpdated : userChanged;

    if (hasUpdates !== hasChanges) {
      setHasChanges(hasUpdates);
    }
  }, [user, userCurrentData, hasChanges, newUser, userUpdatedFields]);

  const showSuccessBanner = useCallback(() => {
    history.push(`${PATHS.SETTINGS}/${SETTINGS_TABS.USERS}`);
    dispatch(setNotificationVariant('success'));
    dispatch(
      setNotificationText(
        !newUser
          ? intl.get('SETTINGS_PAGE.USER_PAGE.USER_UPDATED')
          : intl.get('ADD_USER.USER_ADDED')
      )
    );
    dispatch(displayNotification());
  }, [dispatch, history, newUser]);

  const handleChangeField = (
    inputValue: string | string[] | boolean | number,
    targetPath: string
  ) => {
    let newData: Partial<AllUsersType> = {};
    newData = set(newData, targetPath, inputValue);
    setUserUpdatedFields((prevState) => {
      if (newData.data) {
        newData = {
          data: { ...user.data, ...prevState.data, ...newData.data },
        };
      }

      const updatedUser = {
        ...user,
        ...prevState,
        ...newData,
      };

      const validaionErrors = validateRequiredFields(updatedUser);

      const hasErrors = Object.keys(validaionErrors).length > 0;
      if (hasErrors === canSave) {
        setCanSave(!hasErrors);
      }
      if (!newUser) {
        setErrors(validaionErrors);
        return {
          ...prevState,
          ...newData,
        };
      }
      return {
        ...user,
        ...prevState,
        ...newData,
      };
    });
  };

  const onUpdate = useCallback(async () => {
    let newErrors: objKeyAsString = {};
    if (!Object.keys(errors).length) {
      newErrors = validateRequiredFields(userCurrentData);
      setErrors(newErrors);
    }
    dispatch(setNotificationTimeout(4000));
    if (Object.keys(errors).length || Object.keys(newErrors).length) {
      dispatch(setNotificationVariant('error'));
      dispatch(
        setNotificationText(
          intl.get('SETTINGS_PAGE.USER_PAGE.ERRORS.INLINE_NOTFICATION')
        )
      );
      dispatch(displayNotification());
      return;
    }
    let teamsIds = null;
    let updatedUser, userStatus;
    const assignedTeams = get(userUpdatedFields, 'registeredLearningTeams');
    if (assignedTeams) {
      teamsIds = assignedTeams;
      delete (userUpdatedFields as Partial<LDUser>).registeredLearningTeams;
    }
    if (Object.keys(userUpdatedFields).length) {
      if (!newUser) {
        const email = get(userUpdatedFields, 'data.email');
        if (email && email !== user.data.email) {
          const authUser = await Auth.currentAuthenticatedUser();
          await Auth.updateUserAttributes(authUser, { email });
        }
      }
      if (newUser) {
        updatedUser = await dispatch(addUser(userUpdatedFields));
      } else {
        updatedUser = await dispatch(
          updateUser({ userId, updateFields: userUpdatedFields })
        );
      }
      userStatus = userUpdatedFields.status;

      setHasChanges(false);
      setCanSave(false);
      setUserUpdatedFields({});

      setNewUserId(get(updatedUser, 'payload.id'));
    }

    if (teamsIds) {
      await dispatch(
        assignUserToLearningTeam({
          userId: newUser ? get(updatedUser, 'payload.id') : userId,
          teamsIds,
        })
      );
    }
    if (newUser && userStatus === USER_STATUS.INVITED) {
      setIsNotifyModalOpen(true);
    } else {
      showSuccessBanner();
    }
  }, [
    dispatch,
    errors,
    user.data.email,
    userCurrentData,
    userId,
    userUpdatedFields,
    newUser,
    showSuccessBanner,
  ]);

  const sendUserInvitation = () => {
    dispatch(
      inviteUser({
        userId: newUserId,
        email: userUpdatedFields.data?.email!,
        firstName: userUpdatedFields.data?.firstName!,
      })
    );
    showSuccessBanner();
  };

  const closeNotifyModal = () => {
    setIsNotifyModalOpen(false);
    showSuccessBanner();
  };

  return (
    <div className='h-full flex flex-col'>
      <NotifyUserModal
        isOpen={isNotifyModalOpen}
        closeModal={closeNotifyModal}
        inviteUser={sendUserInvitation}
      />
      <PageTitle
        titleComponent={pageTitle}
        className='sticky top-0 left-0 right-0'
      />
      {(userStatus !== SLICE_STATUS.LOADING && get(user, 'id')) || newUser ? (
        <Fragment>
          <DetailsPage
            topBar={
              !newUser && <UserPageHeader accountStatus={get(user, 'status')} />
            }
            content={
              <UserDetailsSection
                userObj={user}
                handleChangeField={handleChangeField}
                errors={errors}
                isFormDirty={hasChanges}
              />
            }
          />
          <UserPageFooter
            disableSave={!hasChanges || !canSave}
            onUpdate={onUpdate}
            newUser={newUser}
          />
        </Fragment>
      ) : (
        <Loader />
      )}
      <UnsavedChangesPrompt hasChanges={hasChanges} />
    </div>
  );
};

export default UserPage;
