import { FC, ChangeEvent } from 'react';
import intl from 'react-intl-universal';
import { UserOption } from '@getsynapse/design-system/dist/Molecules/UsersPicker/UsersPicker';
import {
  LONG_INPUTS_LENGTH,
  PROJECT_STATUS,
  TASK_STATUS,
} from 'utils/constants';
import { rangeDate } from 'utils/customTypes';
import { TaskDetailProject, TaskDetailToUpdate } from 'types/store/taskDetail';
import SidePanelTitle from 'Atoms/SidePanelTitle/SidePanelTitle';
import ProjectLink from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskProjectLink';
import TaskAssigneesField from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskAssignee';
import TaskDescription from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskDescription';
import TaskDatesField from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskDates';
import TaskTypeField from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskType';
import TaskStatusField from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskStatus';
import TaskEstimatedTimeField from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskEstimateHours';
import TaskActualCompletionDateField from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskActualHourCompletionDate';
import TaskActualHoursField from 'Pages/TaskDetailPage/TaskDetailForm/fields/TaskActualHours';
import StatusBanner from 'Pages/TaskDetailPage/StatusBanner/StatusBanner';

interface SidePanelContentProps {
  task: TaskDetailToUpdate;
  requiredFieldsErrors: Map<string, boolean>;
  project: TaskDetailProject;
  hasAssignedUsers?: boolean;
  canUpdateTask?: boolean;
  isTaskDisabled?: boolean;
  isTaskOnHold?: boolean;
  isTaskDueDatePast?: boolean;
  updateTaskDetail: (
    key: keyof TaskDetailToUpdate,
    value: string | undefined
  ) => void;
  updateTaskDateRange: (range: rangeDate) => void;
  updateTaskAssignees: (assignees: UserOption[]) => void;
  updateTaskStatus: (status: string) => void;
}

const SidePanelContent: FC<SidePanelContentProps> = ({
  task,
  requiredFieldsErrors,
  project,
  canUpdateTask = true,
  hasAssignedUsers = false,
  isTaskDisabled = false,
  isTaskDueDatePast = false,
  isTaskOnHold = false,
  updateTaskAssignees,
  updateTaskDetail,
  updateTaskDateRange,
  updateTaskStatus,
}) => {
  const isReadOnly = [PROJECT_STATUS.CLOSED, PROJECT_STATUS.CANCELED].includes(
    project?.status
  );
  const isViewOnly = isTaskDisabled || isTaskOnHold || !canUpdateTask;

  return (
    <div className='w-full bg-neutral-white flex-grow'>
      <SidePanelTitle
        placeholder={intl.get('TASKS.TASK_DETAIL_PAGE.TASK_TITLE_PLACEHOLDER')}
        value={task?.name ?? ''}
        maxLength={LONG_INPUTS_LENGTH}
        disabled={isViewOnly}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          updateTaskDetail('name', event.target.value);
        }}
        containerProps={{
          className: 'pt-0',
        }}
        hasError={requiredFieldsErrors.get('name') ?? false}
        helpText={
          requiredFieldsErrors.get('name')
            ? intl.get('TASKS.TASK_DETAIL_PAGE.MISSING_INFO_ERROR')
            : ''
        }
      />
      <StatusBanner
        isOnSidepanel
        isTaskDisabled={isTaskDisabled}
        isTaskOnHold={isTaskOnHold}
        isTaskDueDatePast={isTaskDueDatePast}
      />
      <div className='flex flex-col gap-y-5'>
        <ProjectLink
          projectId={project.id ?? ''}
          projectTitle={project.title ?? ''}
        />
        <TaskAssigneesField
          taskId={task.id}
          onAssigneesChange={updateTaskAssignees}
          isReadOnly={isReadOnly}
          isViewOnly={isViewOnly}
        />
        <TaskDescription
          description={task.description}
          isReadOnly={isReadOnly}
          isViewOnly={isViewOnly}
          onDescriptionChange={(description: string) => {
            updateTaskDetail('description', description);
          }}
        />
        <TaskDatesField
          isReadOnly={isReadOnly}
          isViewOnly={isViewOnly}
          hasAssignedUsers={hasAssignedUsers}
          startDate={task.startDate ? new Date(task.startDate) : undefined}
          dueDate={task.dueDate ? new Date(task.dueDate) : undefined}
          onDateChange={updateTaskDateRange}
          hasError={requiredFieldsErrors.get('startDate') ?? false}
        />
        <TaskTypeField
          type={task.type}
          onChangeType={(type: string) => {
            updateTaskDetail('type', type);
          }}
          isReadOnly={isReadOnly}
          isViewOnly={isViewOnly}
        />
        <div className='flex'>
          <TaskStatusField
            status={task.status}
            disabled={
              task.status === TASK_STATUS.ON_HOLD
                ? !canUpdateTask
                : isReadOnly || isTaskDisabled
            }
            hasAssignedUsers={hasAssignedUsers}
            onStatusChange={(status: string) => {
              updateTaskStatus(status);
            }}
          />
          {task?.status === TASK_STATUS.COMPLETED && (
            <TaskActualCompletionDateField
              completionDate={
                task.completionDate ? new Date(task.completionDate) : undefined
              }
              onCompletionDateChange={(date: string | undefined) => {
                updateTaskDetail('completionDate', date);
              }}
              isReadOnly={isReadOnly}
              isViewOnly={isViewOnly}
              hasError={requiredFieldsErrors.get('completionDate') ?? false}
            />
          )}
        </div>
        <TaskEstimatedTimeField
          estimateHours={task.estimateHours}
          onEstimateHoursChange={(estimateHours: string) => {
            updateTaskDetail('estimateHours', estimateHours);
          }}
          isReadOnly={isReadOnly}
          isViewOnly={isViewOnly}
        />
        <TaskActualHoursField />
      </div>
    </div>
  );
};

export default SidePanelContent;
